import React, { useState } from 'react';
import { downloadExcel } from './GenericExcelExport/exportUtils';
import { Loader, Popup } from 'semantic-ui-react';
 import utilities from '../../utils/utilities';
 import {  storePricingDistSummary, priceChangeSummary}  from '../../urlConstants'
 import restUtils from '../../utils/restUtils';
 import { useSelector } from 'react-redux';
const ExportExcel =(props) =>{
  const { storeId } = useSelector(state => state.SessionReducer)
const [loading, setLoading] = useState(false)
const exportToCSV = (name,raDate) => {
    let productsData = []
    let gridPayload = []
    let url = ''
    utilities.showToast("Exporting to Excel...");     
    let row  = {}
    if(name === 'storePricingMix') {
      url = storePricingDistSummary
      gridPayload = {
        "memberNbr": storeId,	
        "filterType": props.filterType.name,
        "filterValues": props.filterType.value,
        "skuType": parseInt(props.skuType),
        "rowsPerPage": props.totalCount,
        "pageNbr": 1,
        "sortColumn": props.sortColumn,
        "distType":  props.distType,
        "sortOrder": props.sortOrder
      }
     }
     else {
      url = priceChangeSummary
      gridPayload = {
        "memberNbr": storeId,	
        "filterType": props.filterType.name,
        "filterValues": props.filterType.value,
        "rowsPerPage": props.totalCount,
        "pageNbr": 1,
        "sortColumn": props.sortColumn,
        "sortOrder": props.sortOrder,
        "priceChangeType": props.priceChangeType
      }
     }

   restUtils.postData(url, gridPayload).then(response=>{
    let data = []
    if(name === 'storePricingMix') {
      data = response.data.skuPrices
    }
    else {
      data = response.data.priceChanges
    }
    data.map((cell,index)=>{
      row = {
        ...row,
        'SKU #':cell.skuNbr,
        'Description':cell.shortDesc,
        'Department': cell.deptName,
         'Class':cell.className,
         'Current SRP ($)':cell.currentSrp,
         'Regular Price ($)':cell.regularPrice,
         'Fair Price ($)': cell.fairPrice,
         'Exp. An. Margin Improv. ($)':cell.marginChange.toFixed(2),
         'Price Override ($)':cell.priceOverride
      }
      productsData.push(row);
    })
    downloadExcel(productsData, name);
  })

}
return (
    <>
      {loading && (
        <div class="exportLoader">
          <Loader active />
        </div>
      )}
      <span className={props.className}>
        <Popup
          trigger={<button className = 'downloadBtn' onClick={() => exportToCSV(props.id,"raDate")}
                  >Download</button>}
          content="Export Excel"
          size='mini'
          position='top right'
          basic
        />          
      </span>
    </>
  );
}

export default ExportExcel
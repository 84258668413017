import React, { useState, useEffect, memo } from "react";
import './dropdown.scss'
import _ from 'lodash';
import utilities from "../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { setFilterType } from "./dropdownSlice";
import InfiniteScroll from 'react-infinite-scroller';
export const DropDown = props =>{
  const [ selectedItem, setSelectedItem] = useState(props.selectedItem)
  const [ showDropDown, setShowDropDown] = useState(false)
  const [ data, setData] = useState(props.data)
  const [ searchItem, setSearchItem] = useState([])
  const { selectedCategory, selectedClass} = useSelector(state => state.DropDownReducer);
  const [ skuSearch, setSkuSearch] = useState('')
  const dispatch = useDispatch()
  useEffect(()=>{
    let catData = props.data.map(product=> {
      let res = {}
      switch(props.id) {
        case 'Dept':
          res =  {'value': product.deptCode, 'name': product.deptName, 'uniqCode': product.deptCode, 'classCode':product.classCode, 'subclassCode':product.subclassCode,}
        break;
        case 'Class':
          res = {'name': product.className, 'value': product.classCode, 'uniqCode':product.uniqClassId, 'deptCode':product.deptCode, 'subclassCode':product.subclassCode}
        break;
        case 'SubClass':
          res = {'name': product.subclassName, 'value': product.subclassCode, 'uniqCode': product.uniqSubClassId, 'deptCode':product.deptCode, 'classCode':product.classCode}
        break;
        case 'Sku':
          res = {'name': product.skuNbr, 'value': product.skuNbr, 'uniqCode': product.skuNbr}
        break;
        default:
          res = {'name': product.name, 'value': product.value , 'uniqCode':''}
      }        
      return res
      })
     setData(catData)
  },[props.data,props.id, showDropDown])
 
  useEffect(()=>{
    const handleClickOutside = (e) => {
      if(!e.target.classList.contains('clearSearch')) {
        if(!utilities.hasParentWithMatchingSelector(e.target, props.id)) {
          setShowDropDown(false)
          setSkuSearch('')
        }
      }
    
     }
    document.addEventListener('click', handleClickOutside);
    return ()=> {
      document.removeEventListener('click', handleClickOutside);
    }
  },[props.id])
  useEffect(()=>{
    setSelectedItem(props.selectedItem)
  },[props.selectedItem])
    const handleSelectAll = (event) => {
      const { type } = event.target.dataset
      const isChecked = event.target.checked
      let filterValue = {
        name: type,
        value:[]
      }
      if(isChecked) {
        props.handleSelectedItem(data)
        filterValue = {
          ...filterValue,
          value: data.map(ele=> ele.uniqCode)
        }
      }
      else {
        props.handleSelectedItem([])
        filterValue = {
          ...filterValue,
          value: []
        }
      }
      dispatch(setFilterType(filterValue))
    } 
    const handleSelectDept =(event) => {
      const { name, type } = event.target.dataset
      let filterValue = {
        name: type,
        value:[]
      }
      const isChecked = event.target.checked
      if(isChecked) {
        const filterSelected = _.find(data,['name',name])
        const filterItem = [...selectedItem, filterSelected]
        filterValue = {
          ...filterValue,
          value: filterItem.map(ele=> ele.uniqCode)
        }
        props.handleSelectedItem(filterItem)
      }
      else {
        const removingItem = selectedItem.filter(ele=> ele.name !== name)
        const filterItem = removingItem
        filterValue = {
          ...filterValue,
          value: removingItem.map(ele=> ele.uniqCode)
        }
        props.handleSelectedItem(filterItem)
      }
        dispatch(setFilterType(filterValue))     
    }
    const onSearchEnterCheckData =(item) => {
      let filterValue = {
        name: "Sku",
        value:[]
      }
      const isChecked = true
      let name = item[0].name;
    if(isChecked) {
      const filterSelected = _.find(data,['name',name])
      const filterItem = [...selectedItem, filterSelected]
      filterValue = {
        ...filterValue,
        value: filterItem.map(ele=> ele.uniqCode)
      }
      props.handleSelectedItem(filterItem)
    }  
      dispatch(setFilterType(filterValue))     
  }
    const itemsPerPage = 20;
    const [records, setrecords] = useState(itemsPerPage);
    const showItems = (opt) => {
     
      let items = [];
      for (let i = 0; i < records; i++) {
        if(!_.isEmpty(opt[i])) {
           if(props.id==='Class') {
            items.push(
              <div key={opt[i].name}><p><input id={opt[i].name} type="checkbox" disabled = {_.isEmpty(selectedCategory)} checked ={_.some(selectedItem,['name',opt[i].name])} data-name={opt[i].name} data-type={props.id} onChange={handleSelectDept}value={opt[i].value}/><label htmlFor={opt[i].name}>{opt[i].name}</label></p></div>
            );
          }else if(props.id==='SubClass') {
            items.push(
              <div key={opt[i].name}><p><input id={opt[i].name} type="checkbox" disabled = {_.isEmpty(selectedClass) ? true: false}checked ={_.some(selectedItem,['name',opt[i].name])} data-name={opt[i].name} data-type={props.id} onChange={handleSelectDept}value={opt[i].value}/><label htmlFor={opt[i].name}>{opt[i].name}</label></p></div>
            );
          }else {
            items.push(
              <div key={opt[i].name}><p><input id={opt[i].name} type="checkbox" checked ={_.some(selectedItem,['name',opt[i].name])} data-name={opt[i].name} data-type={props.id} onChange={handleSelectDept}value={opt[i].value}/><label htmlFor={opt[i].name}>{opt[i].name}</label></p></div>
            );
          }
          
        }        
      }
      return items;
    };
    const loadMore = () => { 
      if (records > props.data.length -1 || (skuSearch && (searchItem.length<=20||records >=searchItem.length))) {      
        //setHasMore(false);
      }else {       
        setTimeout(() => {
          setrecords(records + itemsPerPage);
        }, 2000);
      }
    };
    const handleSkuSearch = (e)=>{
      const { value} = e.target
      const strVal = (value.split('').join()).toString().toLowerCase()
      setrecords(itemsPerPage)
      setSkuSearch(value)
      const filterSearchItem = props.data.filter(item=> _.includes((item.skuNbr.split('').join()).toString().toLowerCase(), strVal))
     
      const searchItem = filterSearchItem.map(product=>{
        return {
          'name': product.skuNbr, 'value': product.skuNbr, 'uniqCode': product.skuNbr
        }
      })
    
     
      setSearchItem(searchItem)
      setData(searchItem)
          
    }
    const handleClearSearch = () =>{
      setSkuSearch('')
      setrecords(itemsPerPage)
      const searchItem = props.data.map(product=>{
        return {
          'name': product.skuNbr, 'value': product.skuNbr, 'uniqCode': product.skuNbr
        }
      })
      setData(searchItem)
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onSearchEnterCheckData(searchItem);        
      }
    }
    const renderDropDown = () => {
       return (
        <div id={props.id}>
          {props.data.length> 0 ?
          <>
          <label  className="headLabel" onClick={()=>{setShowDropDown(state => !state)}}><span>{_.isEmpty(selectedItem) || selectedItem.length === data.length ? 'All' : selectedItem.length === 1 ? selectedItem[0]?.name : 'Multiple selections'}</span><i className="caret down icon"></i></label>
          {showDropDown && (
            <div className="dropDownContent">
              {props.id === 'Sku' && <p className="skuSearch"><input type='text' value={skuSearch} onChange={handleSkuSearch} onKeyDown={handleKeyDown} placeholder="Search"/>{!_.isEmpty(skuSearch) && <i className="close icon clearSearch"  onClick={handleClearSearch}></i>}</p>}
            {data.length >0 && (
            <div>              
              <p><input id='selectAll' type='checkbox' disabled = {props.id ==='Class' && _.isEmpty(selectedCategory) ? true : props.id ==='SubClass' && _.isEmpty(selectedClass) ? true :false }checked={selectedItem.length === data.length} stype="checkbox" data-type={props.id} onChange={handleSelectAll}/><label htmlFor="selectAll">Select All</label></p>
            </div>)}
            <InfiniteScroll
            key="infiteScroll"
              pageStart={0}
              loadMore={loadMore}
              hasMore={true}
              loader={data.length > records  ? <h4 className="loader" key={0}>Loading...</h4>:''}
              useWindow={false}
            >
              {showItems(data)}
            </InfiniteScroll>
            </div>
          )} 
          </>: <label className="headLabel inActive"><span>All</span><i className="caret down icon"></i></label>}  
        </div>
       )
    }
    return(
        <>
          <div className="dropDownWrapper" >
          
            {renderDropDown()}
          </div>
        </>
    )

}

export default memo(DropDown)
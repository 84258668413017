import React, { memo } from "react";
import NavBar from "./NavBar";
import HeroBar from "./HeroBar";
import "./header.scss";
import Category from "../DropDown";

const Header = (props) => {
  return (
    <div className="contentContainer">
      <HeroBar />
      <NavBar />
      <div className="gridContainer">
        {window.location.pathname !== "/addSkuToProductMaster" && <Category />}
        {props.content}
      </div>
    </div>
  );
};

export default memo(Header);

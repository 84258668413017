import { Pagination } from 'semantic-ui-react';

export const getPagination = (boundaryRange, currentPage, totalPages, onPageChange, ellipsisItem, siblingRange) => {
    currentPage = currentPage?parseInt(currentPage):0;
    totalPages = totalPages?parseInt(totalPages):0;
    return (
        <Pagination
        className='pagination pagination-padding-mobile'
          boundaryRange={boundaryRange}
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          ellipsisItem={ellipsisItem}
          siblingRange={siblingRange}
          firstItem={currentPage === 1 ?null:undefined}
          lastItem={currentPage === totalPages ? null:undefined}
          prevItem={currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
          nextItem={currentPage === totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
        />
    );
  }
import React, { useState, useEffect, memo } from "react";
import _ from "lodash";
import utilities from "../../utils/utilities";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
export const DropDown = (props) => {
  const [selectedItem, setSelectedItem] = useState(props.selectedItem);
  const [showDropDown, setShowDropDown] = useState(false);
  const [data, setData] = useState(props.data);
  const [searchItem, setSearchItem] = useState([]);
  const { selectedCategory, selectedClass, seletedSku, seletedSubClass } =
    props;
  const [skuSearch, setSkuSearch] = useState("");
  useEffect(() => {
    let catData = props.data.map((product) => {
      let res = {};
      switch (props.id) {
        case "Dept":
          res = {
            value: product.deptCode,
            name: product.deptName,
            uniqCode: product.deptCode,
            classCode: product.classCode,
            subclassCode: product.subClassCode,
          };
          break;
        case "Class":
          res = {
            name: product.className,
            value: product.classCode,
            uniqCode: product.uniqClassId,
            deptCode: product.deptCode,
            subclassCode: product.subClassCode,
          };
          break;
        case "SubClass":
          res = {
            name: product.subClassName,
            value: product.subClassCode,
            uniqCode: product.uniqSubClassId,
            deptCode: product.deptCode,
            classCode: product.classCode,
          };
          break;
        case "Sku":
          res = {
            name: product.skuNbr,
            value: product.skuNbr,
            uniqCode: product.skuNbr,
          };
          break;
        default:
          res = { name: product.name, value: product.value, uniqCode: "" };
      }
      return res;
    });
    setData(catData);
  }, [props.data, props.id]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.classList.contains("clearSearch")) {
        if (!utilities.hasParentWithMatchingSelector(e.target, props.id)) {
          setShowDropDown(false);
          setSkuSearch("");
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [props.id]);
  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);
  const handleSelectAll = (event) => {
    const { type } = event.target.dataset;
    const isChecked = event.target.checked;
    let filterValue = {
      name: type,
      value: [],
    };
    if (isChecked) {
      props.handleSelectedItem(data);
      filterValue = {
        ...filterValue,
        value: data.map((ele) => ele.uniqCode),
      };
    } else {
      props.handleSelectedItem([]);
      filterValue = {
        ...filterValue,
        value: [],
      };
    }
    props.handleFilterType(filterValue);
  };
  const handleSelectDept = (event, type) => {
    const { name } = event;
    let filterValue = {
      name: type,
      value: [],
    };
    const filterSelected = _.find(data, ["name", name]);
    const filterItem = [filterSelected];
    filterValue = {
      ...filterValue,
      value: filterItem.map((ele) => ele.uniqCode),
    };
    props.handleSelectedItem(filterItem);
    setShowDropDown(false)
  };
  const itemsPerPage = 20;
  const [hasMore, setHasMore] = useState(true);
  const [records, setrecords] = useState(itemsPerPage);
  const showItems = (opt) => {
    let items = [];

    for (let i = 0; i < records; i++) {
      if (!_.isEmpty(opt[i])) {
        items.push(
          <div
          
            key={opt[i].name}
            value={opt[i].value}
            onClick={() => handleSelectDept(opt[i], props.id)}
          >
           <p><label htmlFor={opt[i].name}>{opt[i].name}</label></p> 
          </div>
        );
      }
    }
    return items;
  };
  const loadMore = () => {
    if (
      records > props.data.length - 1 ||
      (skuSearch && (searchItem.length <= 20 || records >= searchItem.length))
    ) {
      //setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 2000);
    }
  };

  const renderDropDown = () => {
    return (
      <div className={props.dropDownStyle} id={props.id} >
        {props.data.length > 0 ? (
          <>
            <label
              className="headLabel"
              onClick={() => {
                setShowDropDown((state) => !state);
              }}
            >
              <span>
                {_.isEmpty(selectedItem)
                  ? `Select ${props.id}`
                  : selectedItem[0]?.name
                  }
              </span>
              <i className="caret down icon"></i>
            </label>
            {showDropDown && (
              <div className="dropDownContent">
                <InfiniteScroll
                  key="infiteScroll"
                  pageStart={0}
                  loadMore={loadMore}
                  hasMore={true}
                  loader={
                    data.length > records ? (
                      <h4 className="loader" key={0}>
                        Loading...
                      </h4>
                    ) : (
                      ""
                    )
                  }
                  useWindow={false}
                >
                  {showItems(data)}
                </InfiniteScroll>
              </div>
            )}
          </>
        ) : (
          <label className="headLabel inActive">
            <span>Select {props.id}</span>
            <i className="caret down icon"></i>
          </label>
        )}
      </div>
    );
  };
  return (
    <>
      <div className="dropDownWrapper">{renderDropDown()}</div>
    </>
  );
};

export default memo(DropDown);

import React from 'react';
const VariablePrice = (props) => {
    return (
        <>
         <section>
         <p>VariablePrice</p>
        </section>
          
        </>     
        
    )
}

export default VariablePrice
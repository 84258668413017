import React, { useEffect, useState, useCallback } from 'react';
import "./style.scss"
import { useDispatch, useSelector } from 'react-redux';
import { setDropDownShow} from '../../components/DropDown/dropdownSlice'
import {suggPricingWeeklyAverages, compPricingPricingSummary, suggPricingPricingSummary} from '../../urlConstants'
import { useImmer } from 'use-immer';
import restUtils from '../../utils/restUtils';
import _ from 'lodash';
import StackedBarChart from './StackedBarChart';
import { Loader, Modal } from 'semantic-ui-react';
import utilities from '../../utils/utilities';
import GridTable from '../../components/GridTable';
import  usePrevious from '../common/usePrevious'
import { header} from '../../components/GridTable/gridHeader'
const CompetitorPrice = (props) =>{
  const { filterType, skuType, selectedCategory} = useSelector(state => state.DropDownReducer)
  const { storeId, programCode } = useSelector(state => state.SessionReducer)
  const [gridSelection, setGridSelection] = useImmer({
    isSingleSku: false,
    skuValue:null,
    skuImage: null
  })
  const [ gridData, setGridData] = useImmer({
    isLoading:true,
    rowData :null,
    totalData:null,
    itemCount:'' ,
  })
  const [ pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 10
 })
  const [ sortCol, setSortCol] = useImmer({
    colIndex:17,
    orderAsc:false,
    perPage: 20,
  })
  const [ initLoad, setInitLoad] = useState(false)
  const prevSortCol = usePrevious(sortCol)
  const dispatch = useDispatch();
  const [ chartData, setChartData] = useImmer({
      isLoading : true,
      data:[]
  })
  const [ weekStartDate, setWeekStartDate] =  useState(null)
  useEffect(()=>{
    if(filterType.name !== 'None' || skuType !== '-1') {
      setInitLoad(true)
    }
  },[filterType, skuType])
  const [ isLoading, setIsLoading] = useState(false)
      useEffect(() => {
        const handleTabClose = event => {
          event.preventDefault();
          dispatch(setDropDownShow(false))
        };
    
        window.addEventListener('beforeunload', handleTabClose);
    
        return () => {
          window.removeEventListener('beforeunload', handleTabClose);
          dispatch(setDropDownShow(false))
        };
      }, [dispatch]);
      useEffect(()=>{
        if(initLoad && (filterType.name !== 'None' || skuType !== '-1')) {
        const payLoad = {
          "memberNbr": storeId,	
          "filterType":  gridSelection.isSingleSku ? 'Sku' :filterType.name,
          "filterValues":  gridSelection.isSingleSku ? [gridSelection.skuValue.toString()] : !_.isEmpty(filterType.value) ? filterType.value :  null,
          "skuType": parseInt(skuType)
        }
        setChartData(draft=>{
          draft.isLoading = true;
        })
        utilities.setLoader(true)
        restUtils.postData(suggPricingWeeklyAverages, payLoad).then(response=>{
          setChartData(draft=>{
            draft.data = response.data.weeklyAverage && !_.isEmpty(response.data.weeklyAverage) ? response.data.weeklyAverage : [];
            draft.isLoading = false;
          })
         utilities.setLoader(false)
         }).catch(err=>{
          console.log(err)
          setChartData(draft=>{
            draft.isLoading = false;
          })
          utilities.setLoader(false)
        }) 
      }
      },[storeId, initLoad, selectedCategory, gridSelection, filterType, skuType])
      useEffect(()=>{
        setGridSelection(draft=>{
          draft.isSingleSku= false;
          draft.skuImage = null
        })
       },[skuType])
      useEffect(()=>{
        const payload = {
          "memberNbr": storeId,	
          "filterType": filterType.name,
          "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
          "skuType": parseInt(skuType),
          "weekStartDate": null,
          "rowsPerPage": sortCol.perPage,
          "pageNbr": 1,
          "sortColumn": sortCol.colIndex,
          "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
       }
        fetchSkuSummary(payload)
       },[storeId,initLoad,filterType, skuType]) 
    const fetchSkuSummary = (payload = {}) =>{
      if(initLoad && (filterType.name !== 'None' || skuType !== '-1')) {
      let gridPayload = payload;
      setIsLoading(true) 
      restUtils.postData(suggPricingPricingSummary, gridPayload).then(response=>{
       const data = response.data
       setGridData(draft=>{
         draft.rowData = data.suggPrices && !_.isEmpty(data.suggPrices) ? data.suggPrices : [];
         draft.totalData = data.skuTotal;
         draft.itemCount = data.skuTotal.totalCount;
         draft.isLoading = false
       })
       setPagination(draft=>{
         draft.totalPages = data.skuTotal.pageCount
       })
       setIsLoading(false) 
      }).catch(err=>{
       console.log(err)
       setGridData(draft=>{
         draft.isLoading = false
       })
       setIsLoading(false) 
      })
    }
    }
      const onPageChange = e => {
        const text = e.currentTarget.text
        let page = ''
        setGridSelection(draft=>{
          draft.isSingleSku= false;
          draft.skuValue=null;
          draft.skuImage= null;
        })
        if(!isNaN(parseInt(e.currentTarget.text))) {
           setPagination(draft=>{
              draft.currentPage = parseInt(text);
           })
           page = parseInt(text)
        }
        if (e.currentTarget.text === 'Next') {
           setPagination(draft=>{
              draft.currentPage = draft.currentPage < draft.totalPages
              ? parseInt(draft.currentPage) + 1
              : draft.currentPage;
           })
           page = pagination.currentPage < pagination.totalPages
           ? parseInt(pagination.currentPage) + 1
           : pagination.currentPage
        }
        if (e.currentTarget.text === 'Prev') {
           setPagination(draft=>{
              draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
           })
           page =  pagination.currentPage === 1 ? 1 : pagination.currentPage - 1;
        }
        if (e.currentTarget.text === '»') {
           setPagination(draft=>{
              draft.currentPage = draft.totalPages;
           })
           page = pagination.totalPages;
        }
        if (e.currentTarget.text === '«') {
          setPagination(draft=>{
           draft.currentPage = 1
        })
          page =  1;
     
        }
        const payload = {
          "memberNbr": storeId,	
          "filterType": filterType.name,
          "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
          "skuType": parseInt(skuType),
          "weekStartDate": weekStartDate,
          "rowsPerPage": sortCol.perPage,
          "pageNbr": page,
          "sortColumn": sortCol.colIndex,
          "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
       }
        fetchSkuSummary(payload)
      };
      const handleCount = (e)=>{
        const { value}= e.target
        setSortCol(draft=>{
           draft.perPage = value
        })
        setPagination(draft=>{
          draft.currentPage = 1
        })
        setGridSelection(draft=>{
          draft.isSingleSku= false;
          draft.skuValue=null;
          draft.skuImage= null;
        })
        const payload = {
          "memberNbr": storeId,	
          "filterType": filterType.name,
          "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
          "skuType": parseInt(skuType),
          "weekStartDate": weekStartDate,
          "rowsPerPage": value,
          "pageNbr": 1,
          "sortColumn": sortCol.colIndex,
          "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
       }
        fetchSkuSummary(payload)
     }
     const resetSingleSelection = ()=>{
      setGridSelection(draft=>{
        draft.isSingleSku= false;
        draft.skuValue=null;
        draft.skuImage= null;
      })
     }
     const handleSort = (cell, index)=>{
      setSortCol(draft=>{
       draft.colIndex = index;
       draft.orderAsc = index === 17 ? !draft[cell.key] : draft[cell.key]
       draft[cell.key] = !draft[cell.key]
      })
      resetSingleSelection()
     }
     useEffect(()=>{
      if(prevSortCol !== undefined && prevSortCol !== sortCol) {
      const payload = {
          "memberNbr": storeId,	
          "filterType": filterType.name,
          "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
          "skuType": parseInt(skuType),
          "weekStartDate": weekStartDate,
          "rowsPerPage": sortCol.perPage,
          "pageNbr": pagination.currentPage,
          "sortColumn": sortCol.colIndex,
          "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
        }
          fetchSkuSummary(payload)
        }
     },[sortCol])
     const handlePiChartSelection = (val) =>{
      setPagination(draft=>{
        draft.currentPage = 1
      })
      setWeekStartDate(val)
      const payload = {
        "memberNbr": storeId,	
        "filterType": filterType.name,
        "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
        "skuType": parseInt(skuType),
        "weekStartDate": val,
        "rowsPerPage": sortCol.perPage,
        "pageNbr": 1,
        "sortColumn": sortCol.colIndex,
        "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
     }
      fetchSkuSummary(payload)
    }
     const handleRowSelection = (cell,  header = false) => {
      if(!_.isEmpty(cell)) {
        setGridSelection(draft=>{
          draft.skuValue = cell.skuNbr;
          draft.isSingleSku = true;
          draft.skuImage= cell.isTvSku ? cell.skuNbr: null;
        })
        
      }else {
          resetSingleSelection()
      }
    }
    useEffect(()=>{
      setPagination(draft=>{
        draft.currentPage = 1
      })
      resetSingleSelection()
      setWeekStartDate(null)
    },[filterType])
      return (
        <>
        {programCode !== 'ROS' && (
          <>
         {isLoading && <div className='commonLoader'><Loader active/></div>}
             <section className='stackedBarChartWrapper'>
              {filterType.name !== 'None'||  skuType !== '-1'? (
                <div className='stackedBarChart suggChart'>
              {chartData.data.length > 0 ?  <StackedBarChart data={chartData.data} handleSelection = {handlePiChartSelection} /> : !chartData.isLoading && <p className='noDataMsg'>Insufficient data</p>}                 
              </div>
              ):<div className='noSelectionMsg'><p>Please make a selection from the dropdown or from the table to active the graph.</p></div>}
              
              
              </section> 
              <section className='gridTableSection'>
              {filterType.name === 'None' && skuType === '-1' ?
            <div className="gridTableWrapper">
            <div className="countPerPage">
              <div className="itemCountDiv">
                <div><p>Item Count</p>
                      <h3>0</h3>
                    </div>
              </div>
              </div>
      <div className="gridTable">      
      <table>
         <thead>
            <tr>
              {header['suggPrice'].map((cell,index)=>{          
               return(
                <th key={cell.key} >               
                    {cell.name}
                </th>
               )
              })}
              {props.id === 'priceOverride' && <th> Remove</th>}
            </tr>
         </thead>
         </table>
         <div className='gridNoDataBox'>
              <h3>No Data to Display</h3>
              <p>Please make a selection</p>
         </div>
         </div> </div> :
         <>
                {!gridData.isLoading ? (
                  gridData.rowData && (
                    gridData.rowData.length > 0 ? (
                      <GridTable 
                      id={'suggPrice'}
                      data = {gridData}
                      pagination = {pagination}
                      sortCol = {sortCol}
                      onPageChange={onPageChange}
                      handleCount={handleCount}
                      handleSort ={handleSort}
                      handleSelection = {handleRowSelection}
                      image={gridSelection.skuImage}
                      isSkuSelection = {gridSelection.isSingleSku}
                      defaultSort= {17}
                      filterType = {filterType}
                      sortColumn= {sortCol.colIndex}
                      sortOrder=  {sortCol.orderAsc ?  'asc': 'desc'}
                      />
                      
                    ): <p className='noDataMsg'>Insufficient data</p>)
                    ):<Loader active />}  
                    </>
              }
              </section>   
        </>
      )}
      </>
      )
}

export default CompetitorPrice
import React, { memo, useState, useEffect} from 'react';
import { useImmer } from 'use-immer';
import "./style.scss"
import { useSelector } from 'react-redux';
import {priceChangeSummary,  priceChangeBreakdown} from '../../urlConstants'
import GridTable from '../../components/GridTable';
import PriceViewDonut from './priceViewDonut'
import { Loader } from 'semantic-ui-react';
import restUtils from '../../utils/restUtils';
import _ from 'lodash';
import  usePrevious from '../common/usePrevious'
import utilities from "../../utils/utilities";
export const PriceView = (props) => {
  //const [ filterType , setFilterType] = useState(props.filterType)
  const { filterType} = props
  const { storeId, programCode } = useSelector(state => state.SessionReducer)
  const [pieChartData, setPieChartData] = useState([])
  const { selectedCategory, seletedSku} = useSelector(state => state.DropDownReducer)
  const [gridSelection, setGridSelection] = useImmer({
    isSingleSku: false,
    skuValue:null,
    skuImage: null,
    dept: null
  })
  const [ pieSelection, setPieSelection] =  useImmer({
      isSinglePie : false,
      dept:null,
  })
  const [ gridData, setGridData] = useImmer({
    isLoading:true,
    rowData :null,
    totalData:null,
    itemCount:'' ,
  })
  const [ pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 10
 })
 const [ sortCol, setSortCol] = useImmer({
  colIndex:8,
  orderAsc:false,
  perPage: 20,
})
const [ initLoad, setInitLoad] = useState(false)
const prevSortCol = usePrevious(sortCol)
   useEffect(()=>{
    let  pieChartPayload = {
      "memberNbr": storeId,
      "filterType": gridSelection.isSingleSku ? 'Sku' : filterType.value && filterType.value.length > 0 ? filterType.name: "None",
      "filterValues": gridSelection.isSingleSku ? [gridSelection.skuValue] : filterType.value,
     }
    if(filterType.name==='Sku') {
      pieChartPayload = {
        "memberNbr": storeId,
        "filterType": filterType.value.length === 0 ? 'Dept': 'Sku',
        "filterValues":filterType.value,
       }

    }
    restUtils.postData(priceChangeBreakdown, pieChartPayload).then(response=>{
      let result = response.data.priceChange
      result.sort((a,b)=> b.priceChange - a.priceChange)
      setPieChartData(result)
     }).catch(err=>{
      console.log(err)
    })   
   },[storeId,gridSelection,filterType])
   useEffect(()=>{
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "rowsPerPage": sortCol.perPage,
      "pageNbr": 1,
      "sortColumn": sortCol.colIndex,
      "priceChangeType" :null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
   fetchSkuSummary(gridPayload)
   },[storeId,filterType])
   const fetchSkuSummary = (payload = {}) => {
    let gridPayload = payload;
    setGridData(draft=>{
      draft.isLoading = true
    })
    utilities.setLoader(true)
     restUtils.postData(priceChangeSummary, gridPayload).then(response=>{
      const data = response.data
      setGridData(draft=>{
        draft.rowData = data.priceChanges;
        draft.totalData = data.skuTotal;
        draft.itemCount = data.skuTotal.totalCount;
      })
      setPagination(draft=>{
        draft.totalPages = data.skuTotal.pageCount
      })
      setGridData(draft=>{
        draft.isLoading = false
      })
      utilities.setLoader(false)
     }).catch(err=>{
      console.log(err)
      setGridData(draft=>{
        draft.isLoading = false
      })
      utilities.setLoader(false)
     })
   }

  const onPageChange = e => {
    const text = e.currentTarget.text
    let page = ''
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuValue=null;
      draft.skuImage= null;
      draft.deptCode= null;
    })
    if(!isNaN(parseInt(e.currentTarget.text))) {
       setPagination(draft=>{
          draft.currentPage = parseInt(text);
       })
       page = parseInt(text)
    }
    if (e.currentTarget.text === 'Next') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage < draft.totalPages
          ? parseInt(draft.currentPage) + 1
          : draft.currentPage;
       })
       page = pagination.currentPage < pagination.totalPages
       ? parseInt(pagination.currentPage) + 1
       : pagination.currentPage
    }
    if (e.currentTarget.text === 'Prev') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
       })
       page =  pagination.currentPage === 1 ? 1 : pagination.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
       setPagination(draft=>{
          draft.currentPage = draft.totalPages;
       })
       page = pagination.totalPages;
    }
    if (e.currentTarget.text === '«') {
      setPagination(draft=>{
       draft.currentPage = 1
    })
      page =  1;
 
    }
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "rowsPerPage": sortCol.perPage,
      "pageNbr": page,
      "sortColumn": sortCol.colIndex,
      "priceChangeType" :pieSelection.dept ? pieSelection.dept[0] : null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
   fetchSkuSummary(gridPayload)
  };
  const handleCount = (e)=>{
    const { value}= e.target
    setSortCol(draft=>{
       draft.perPage = value
    })
    setPagination(draft=>{
      draft.currentPage = 1
    })
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuValue=null;
      draft.skuImage= null;
      draft.dept= null;
    })
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "rowsPerPage": value,
      "pageNbr": 1,
      "sortColumn": sortCol.colIndex,
      "priceChangeType" :pieSelection.dept ? pieSelection.dept[0] : null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
   fetchSkuSummary(gridPayload)
 }
 const resetSingleSelection = ()=>{
  setGridSelection(draft=>{
    draft.isSingleSku= false;
    draft.skuValue=null;
    draft.skuImage= null;
    draft.dept= null;
  })
  setPieSelection(draft=>{
    draft.isSinglePie= false;
    draft.dept= null;
  })
 }
 const handleSort = (cell, index)=>{

  setSortCol(draft=>{
   draft.colIndex = index;
   draft.orderAsc = index === 8 ? !draft[cell.key] : draft[cell.key]
   draft[cell.key] = !draft[cell.key]
  })
  
  resetSingleSelection()
 }
 useEffect(()=>{
  if(prevSortCol !== undefined && prevSortCol !== sortCol) {
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "rowsPerPage": sortCol.perPage,
      "pageNbr": pagination.currentPage,
      "sortColumn": sortCol.colIndex,
      "priceChangeType" :pieSelection.dept ? pieSelection.dept[0] : null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
    }
  fetchSkuSummary(gridPayload)
  }
 },[sortCol])
 const handlePiChartSelection = (value) =>{
  setPieSelection(draft=>{
    draft.isSinglePie = !_.isEmpty(value) ? true : false;
    draft.dept = !_.isEmpty(value) ? [value] : null
  })
  setPagination(draft=>{
    draft.currentPage = 1;
  })
  const gridPayload = {
    "memberNbr": storeId,	
    "filterType": filterType.name,
    "filterValues": filterType.value,
    "rowsPerPage": sortCol.perPage,
    "pageNbr": 1,
    "sortColumn": sortCol.colIndex,
    "priceChangeType" :!_.isEmpty(value) ? value : null,
    "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
 }
 fetchSkuSummary(gridPayload)
 }
 const handleRowSelection = (cell,  header = false) => {
  if(!_.isEmpty(cell)) {
    setGridSelection(draft=>{
      draft.skuValue = cell.skuNbr;
      draft.dept = cell.deptCode;
      draft.isSingleSku = true;
      draft.skuImage= cell.isTvSku ? cell.skuNbr: null;
    })
    
  }else {
    if(pieSelection.isSinglePie) {
      setGridSelection(draft=>{
        draft.isSingleSku= false;
        draft.skuValue=null;
        draft.skuImage= null;
        draft.dept= null;
      })
    }else {
      resetSingleSelection()
    }   
  }

}
useEffect(()=>{
  setPagination(draft=>{
    draft.currentPage = 1
  })
  resetSingleSelection()
},[filterType])
  return(
    <>
    {programCode !== 'ROS' && (
      <>
    <section className='chartWrapper priceChartWrapper'>
      <div className='donutChartWrapper'>
      <PriceViewDonut data = {pieChartData} handleSelection={handlePiChartSelection}  isSinglePieCode = {pieSelection.dept} resetSelection={pieSelection.isSinglePie}  gridSelection={gridSelection.isSingleSku}/>
      </div>
    </section>
    <section className='gridTableSection' id='priceView'>
    {gridData.isLoading && <Loader active/>}
      {gridData.rowData && (
      gridData.rowData.length > 0 ? (
        <>
        <GridTable 
        id={'priceView'}
        data = {gridData}
        pagination = {pagination}
        sortCol = {sortCol}
        onPageChange={onPageChange}
        handleCount={handleCount}
        handleSort ={handleSort}
        handleSelection = {handleRowSelection}
        image={gridSelection.skuImage}
        isSkuSelection = {gridSelection.isSingleSku}
        defaultSort= {8}
        filterType = {filterType}
        sortColumn= {sortCol.colIndex}
        sortOrder=  {sortCol.orderAsc ?  'asc': 'desc'}
        priceChangeType = {pieSelection.dept ? pieSelection.dept[0] : null}
         />
        </>
        
        
      ): <p className='noDataMsg'>Insufficient data</p>
      )} 
    </section>
    </>
    )}
    </>
  )
}
export default memo(PriceView);
import React, { memo, useEffect } from 'react';
import DropDown from './DropDown';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCategory,setSelectedClass,setSeletedSubClass,setSelectedSku, setSkuType, reset, setSku } from "./dropdownSlice";
import {getStoreSkus} from '../../urlConstants'
import _ from 'lodash'
import restUtils from '../../utils/restUtils';
import { WHAT_IF_Cal, PRICE_CHANGE_SUMMARY } from '../../views/common/constant';
import whatIfCalBg from "../../../src/images/bg_whatIfCal.JPG";
import storeBg from "../../../src/images/bg_with_chart.jpg"
import priceChangeBg from "../../../src/images/bg_priceChange.jpg"
import compPriceBg from "../../../src/images/bg_compPrice.jpg"
import productMasterBg from "../../../src/images/bg_producMaster.jpg"
import suggPriceBg from "../../../src/images/bg_suggPrice.JPG"
export const Category = (props) => {
  const { finelineHierarchy, classData, sku, selectedCategory, selectedClass,seletedSku, subClassData,seletedSubClass, skuType, filterType, hideDropDown} = useSelector(state => state.DropDownReducer)
  const { storeId, programCode } = useSelector(state => state.SessionReducer)
  const dispatch = useDispatch();

  const processClassData = _.isEmpty(selectedCategory) ? finelineHierarchy :  classData
  const processSubClassData = _.isEmpty(selectedClass) ? finelineHierarchy :  subClassData

  let filterCategory =  _.uniqBy(finelineHierarchy, obj=>obj.deptName)
      filterCategory = _.orderBy(filterCategory, ['deptName'], ['asc'])

  let filterClass = _.uniqBy(processClassData, obj=>obj.className)
      filterClass = !_.isEmpty(selectedCategory) ? _.orderBy(filterClass, ['className'], ['asc']) : []

  let filterSubClass = _.uniqBy(processSubClassData, obj=>obj.subclassName).sort((a,b)=> {return a-b})
      filterSubClass = !_.isEmpty(selectedClass) ? _.orderBy(filterSubClass, ['subclassName'], ['asc']) : []

  const skuTypeDropDown = [
    {name:'All', value:'-1'},
    {name:'TV', value:'1'},
    {name:'Non-TV', value:'0'}
  ]
  useEffect(()=>{
    if(storeId  &&  filterType.name !== 'Sku' && window.location.pathname !== '/finalizePriceOverride') {
      const payload = {
        "filterType": filterType.name,
        "filterValues": filterType.value,
        "memberNbr": storeId,
        "skuType": parseInt(skuType)
       }
       restUtils.postData(getStoreSkus, payload).then(response=>{
        dispatch(setSku(response.data.skus))
       })
    }
   
  },[storeId,skuType,filterType,dispatch])
  const handleSelectedCategory = (items) => {
    dispatch(setSelectedCategory(items))
    resetOption();
  }
  const handleSelectedClass = (items) => {
    dispatch(setSelectedClass(items))
    dispatch(setSeletedSubClass([]))
    dispatch(setSelectedSku([]))
  }
  const handleSelectedSubClass =(items)=>{
    dispatch(setSeletedSubClass(items))
    dispatch(setSelectedSku([]))
  }
  const handleSelectedSku = (items)=>{
    dispatch(setSelectedSku(items))
  }
  const handleSelectedSkuType =(event)=>{
    const { value } = event.target
    dispatch(setSkuType(value))
  }
  const resetOption =() =>{
    dispatch(setSelectedClass([]))
    dispatch(setSeletedSubClass([]))
    dispatch(setSkuType('-1'))
    dispatch(setSelectedSku([]))
  }
  const processMsg = () =>{
    const path = window.location.pathname
     if(path===`/${WHAT_IF_Cal}`) {
      return (
        <>
          <img src={whatIfCalBg} alt='whatIfCalBg' />
          <p>What If Calculator feature is reserved for Gold/Platinum RPO program subscriptions. Please reach out to Pricing team to upgrade.</p>
        </>
      )
     }
     else if(path===`/${PRICE_CHANGE_SUMMARY}`) {
      return (
          <>
          <img src={priceChangeBg} alt='priceChangeBg'/>
          <p>Price Change Summary feature is reserved for Gold/Platinum RPO program subscriptions. Please reach out to Pricing team to upgrade.</p>
      </>
      )
     
     }else if(path==="/competitorPrices") {
      return (
          <>
          <img src={compPriceBg} alt='compPriceBg'/>
          <p>Competitor Price feature is reserved for Gold/Platinum RPO program subscriptions. Please reach out to Pricing team to upgrade.</p>
      </>
      )
     
     }
     else if(path==="/productMasterList") {
      return (
          <>
          <img src={productMasterBg} alt='productMasterBg' />
          <p>Product Master feature is reserved for Gold/Platinum RPO program subscriptions. Please reach out to Pricing team to upgrade.</p>
      </>
      )
     
     }
     else if(path==="/suggestedPricingDetail") {
      return (
          <>
          <img src={suggPriceBg} alt='suggPriceBg' />
          <p>Suggested Pricing Detail feature is reserved for Gold/Platinum RPO program subscriptions. Please reach out to Pricing team to upgrade.</p>
      </>
      )
     
     }
     else {
      return (
          <>
          <img src={storeBg} alt='storeBg'  />
          <p>Store Financials feature is reserved for Gold/Platinum RPO program subscriptions. Please reach out to Pricing team to upgrade.</p>
      </>
      )
     
     }
  }
  return(
    programCode !== 'ROS' ?
    <>
    {!hideDropDown  && window.location.pathname !== '/finalizePriceOverride' && window.location.pathname !== '/competitorPrices' && (
    <div className='dropDownContainer'>
      <div>
        <h4>Category</h4>
        <DropDown id='Dept' data = {filterCategory} handleSelectedItem = {handleSelectedCategory} selectedItem={selectedCategory} />
      </div>
      {window.location.pathname !== `/${WHAT_IF_Cal}` && (
        <>
        <div>
          <h4>Class</h4>
          <DropDown id='Class' data = {filterClass} handleSelectedItem = {handleSelectedClass} selectedItem={selectedClass} />
          </div>
          <div>
          <h4>Sub Class</h4>
          <DropDown id='SubClass' data = {filterSubClass} handleSelectedItem = {handleSelectedSubClass} selectedItem={seletedSubClass} />
          </div>
          </>
      )}
          <div>
          <h4>Sku Type</h4>
          <div className='skuTypeSelect'>
          <i className="caret down icon"></i>
          <select value={skuType}onChange={handleSelectedSkuType} disabled = {_.isEmpty(finelineHierarchy)}>
              {skuTypeDropDown.map(opt=>{
                return(
                  <option key={opt.value} value={opt.value}>{opt.name}</option>
                )
              })}
            </select>       
          </div>        
          </div>

    {window.location.pathname !== '/productMasterList' && (
      <div>
        <h4>Sku</h4>
        <DropDown id='Sku' data = {sku} handleSelectedItem = {handleSelectedSku} selectedItem={seletedSku} />
      </div>
    )}
      <button className='dropdownResetBtn' onClick={()=> dispatch(reset())}>Reset</button>
    </div> 
    )}
    </> : <div className='silverUserWrapper'>    
        {processMsg()}
    </div>
  )
}

export default memo(Category);
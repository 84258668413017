import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import "./style.scss";
import { useSelector } from 'react-redux';
import history from '../../history';
import restUtils from '../../utils/restUtils';
import { Prompt } from 'react-router-dom';
import { useImmer } from 'use-immer';
import {pendingFinalize, deleteNewPrice,finalizeNewPrice} from '../../urlConstants'
import { Loader } from 'semantic-ui-react';
import GridTable from '../../components/GridTable';
import utilities from '../../utils/utilities';
function FinalizePriceOverride (props) {
    const { storeId, storeDetails } = useSelector(state => state.SessionReducer)
    const  [ selectedStoreGroup, setSelectedStoreGroup] = useState(false)
    const [ gridData, setGridData] = useImmer({
        isLoading:true,
        rowData :null,
        totalData:null,
        itemCount:'' ,
      })
      const [ pagination, setPagination] = useImmer({
        currentPage: 1,
        totalPages: 10
     })
     const [ sortCol, setSortCol] = useImmer({
      colIndex:2,
      orderAsc:false,
      perPage: 20,
    })
    const [ constData, setConstData] = useImmer({
      rowData :null,
        totalData:null,
        itemCount:'' ,
    })
    const [ reloadGrid, setReloadGrid] = useState(false)
    useEffect(()=>{
        if(!props.location.state?.showPriceOverride){
           history.push({
            pathname:'/whatIfCalculator'
           })
        } 
    },[])
    useEffect(()=>{    
        const gridPayload = {
         "memberNbr": storeId,	
         "rowsPerPage": sortCol.perPage,
         "pageNbr": pagination.currentPage,
         "sortColumn": sortCol.colIndex,
         "sortOrder": sortCol.orderAsc ?  'asc': 'desc'
      }
      restUtils.postData(pendingFinalize, gridPayload).then(response=>{
       setGridData(draft=>{
         draft.isLoading = true
       })
       const data = response.data
       if(_.isEmpty(data.pendingOverrides)) {
        setShowPrompt(false)
       }
       setGridData(draft=>{
         draft.isLoading = false
       })
       setReloadGrid(false)
       setGridData(draft=>{
        draft.rowData = data.pendingOverrides;
        draft.totalData = data.totals.totalCount;
        draft.itemCount = data.totals.totalCount;
      })
      setConstData(draft=>{
        draft.rowData = data.pendingOverrides;
        draft.totalData = data.totals.totalCount;
        draft.itemCount = data.totals.totalCount;
      })
      setPagination(draft=>{
        draft.totalPages = Math.ceil(data.totals.totalCount/sortCol.perPage)
      })
   
      }).catch(err=>{
       console.log(err)
       setGridData(draft=>{
         draft.isLoading = false
       })
      })
      },[storeId,sortCol,pagination,reloadGrid])
   
    const onPageChange = e => {
        const text = e.currentTarget.text
        if(!isNaN(parseInt(e.currentTarget.text))) {
           setPagination(draft=>{
              draft.currentPage = parseInt(text);
           })
        }
        if (e.currentTarget.text === 'Next') {
           setPagination(draft=>{
              draft.currentPage = draft.currentPage < draft.totalPages
              ? parseInt(draft.currentPage) + 1
              : draft.currentPage;
           })
        }
        if (e.currentTarget.text === 'Prev') {
           setPagination(draft=>{
              draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
           })
        }
        if (e.currentTarget.text === '»') {
           setPagination(draft=>{
              draft.currentPage = draft.totalPages;
           })
        }
        if (e.currentTarget.text === '«') {
          setPagination(draft=>{
           draft.currentPage = 1
        })
     
        }
      };
      const handleCount = (e)=>{
        const { value}= e.target
        setSortCol(draft=>{
           draft.perPage = value
        })
        setPagination(draft=>{
          draft.currentPage = 1
        })
     }
     const handleSort = (cell, index)=>{
    
      setSortCol(draft=>{
       draft.colIndex = index;
       draft.orderAsc = index === 2 ? !draft[cell.key] : draft[cell.key]
       draft[cell.key] = !draft[cell.key]
      })
     }
     const handleDeleteRow = (cell) => {
      const filterItem = gridData.rowData.filter(ele => cell.includes(ele.skuNbr))
      const displayItem = gridData.rowData.filter(ele => !cell.includes(ele.skuNbr))
      setGridData(draft=>{
        draft.rowData =displayItem;
        draft.totalData =displayItem.length;
        draft.itemCount = displayItem.length;
      })
      const gridPayload = {
        "memberNbr": storeId,	
        "skuNbrs": filterItem.map(ele=> parseInt(ele.skuNbr)),
     }
     utilities.setLoader(true)
     restUtils.deleteData(deleteNewPrice, gridPayload).then(response=>{
      utilities.showToast("Successful delete")
      setReloadGrid(true)
      utilities.setLoader(false)
     }).catch((err)=>{
      utilities.showToast("Internal Server error", true)
      utilities.setLoader(false)
     })
    }
    const handleRowSelection = () =>{
      return
    }
    const [showPrompt, setShowPrompt] = useState(true)
    function goBack() {
      history.push({
        pathname: `/whatIfCalculator`,
      });
    } 
    const handleFinalize = () =>{
      let cascadeStoreGroup = [] 
      if(parseInt(selectedStoreGroup)) {
         cascadeStoreGroup = storeDetails.storeGroup.length > 0 ? storeDetails.storeGroup.map(ele=> ele.memberNbr) : []
      }
      const payload = {
         "memberNbr": storeId,
         cascadeStoreGroup: cascadeStoreGroup
      }
      utilities.setLoader(true)
      setShowPrompt(false)
     restUtils.putData(finalizeNewPrice, payload).then(res=>{
         utilities.setLoader(false)
         utilities.showToast('Price Override submitted successfully')
         history.push({
            pathname:'/whatIfCalculator'
           })
     }).catch((err)=>{
        setShowPrompt(true)
         utilities.setLoader(false)
         utilities.showToast("Internal Server error", true)
     })
   }  
   const handleSelectGroup = (e) =>{
      setSelectedStoreGroup(e.target.value)
   }
   useEffect(()=>{
    if(_.isEmpty(gridData?.rowData)) {
      setShowPrompt(false)
    }
    else {
      setShowPrompt(true)
    }
   },[gridData])
    return(
        <>
        <Prompt
        when={showPrompt}
        message="You have pending price overrides to finalize. It will be lost if not finalized."
      />
        <section className='priceOverrideWrapper'>
            <div className='backLinkDiv'>
                <a onClick={goBack}>Back to What-If Calculator </a>
            </div>

        </section>
        <section className='gridTableSection priceOverrideGrid'>
            {!gridData.isLoading ? (
                gridData.rowData && (
                gridData.rowData.length > 0 ? (
                    <GridTable 
                    id={'priceOverride'}
                    data = {gridData}
                    pagination = {pagination}
                    sortCol = {sortCol}
                    onPageChange={onPageChange}
                    handleCount={handleCount}
                    handleSort ={handleSort}
                    defaultSort= {2}
                    handleSelection= {handleRowSelection}
                    handleDeleteRow = {handleDeleteRow}
                    handleSelectGroup = {handleSelectGroup}
                    handleFinalize = {handleFinalize}
                    />
                    
                ): <p className='noDataMsg'>There are no pending price overrides to finalize.</p>)
            ):<Loader active/>}  
        </section>
        </>
    )
}
export default FinalizePriceOverride;
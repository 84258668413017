export const mainMenu = [
    {
        key:'storeFinancials',
        title:'Store Financials',
        url:'/home'
    },
    {
        key:'priceChangeSummary',
        title:'Price Change Summary',
        url:'/priceChangeSummary'
    },
    {
        key:'SuggestedPricingDetail',
        title:'Suggested Pricing Detail',
        url:'/suggestedPricingDetail'
    },
    {
        key:'',
        title:'Competitor Prices',
        url:'/competitorPrices'
    },
    {
        key:'whatIfCalculator',
        title:'What-If Calculator',
        url:'/whatIfCalculator'
    },
    {
        key:'productMasterList',
        title:'Product Master List',
        url:'/productMasterList'
    }
]
import React, { useState, useEffect } from 'react';
import "./style.scss"
import Donut from './Donut'
import { useSelector } from 'react-redux';

import {storePricingDistSummary,  storePricingDistribution} from '../../urlConstants'
import GridTable from '../../components/GridTable';
import restUtils from '../../utils/restUtils';
import { useImmer } from 'use-immer';
import { Loader } from 'semantic-ui-react';
import { header} from '../../components/GridTable/gridHeader'
import _ from 'lodash';
import utilities from "../../utils/utilities";
import  usePrevious from '../common/usePrevious'
export const StorePriceMix = (props) => {
  const { filterType, skuType, selectedCategory} = useSelector(state => state.DropDownReducer)
  const { storeId, programCode } = useSelector(state => state.SessionReducer)

  const [pieChartData, setPieChartData] = useState([])
  const [ isLoading, setIsLoading] = useState(false)
  const [gridSelection, setGridSelection] = useImmer({
    isSingleSku: false,
    skuValue:null,
    skuImage: null,
    deptCode: null
  })
  const [ pieSelection, setPieSelection] =  useImmer({
      isSinglePie : false,
      deptCode:null,
  })
 
  const [ gridData, setGridData] = useImmer({
    isLoading:true,
    rowData :null,
    totalData:null,
    itemCount:'' ,
  })
  const [ pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 10
  })
 const [ sortCol, setSortCol] = useImmer({
  colIndex:8,
  orderAsc:false,
  perPage: 20,
  })
  const prevSortCol = usePrevious(sortCol)
   useEffect(()=>{
    const value = selectedCategory.map(ele => ele.uniqCode)
    let  pieChartPayload = {
      "memberNbr": storeId,
      "filterType": gridSelection.isSingleSku ? 'Sku' : value.length > 0 ? filterType.name: "None",
      "filterValues": gridSelection.isSingleSku ? [gridSelection.skuValue] : filterType.value,
      "skuType": parseInt(skuType)
     }
    if(filterType.name==='Sku') {
      pieChartPayload = {
        "memberNbr": storeId,
        "filterType": filterType.value.length === 0 ? 'Dept': 'Sku',
        "filterValues":filterType.value,
        "skuType": parseInt(skuType)
       }

    }
    setIsLoading(true)
    restUtils.postData(storePricingDistribution, pieChartPayload).then(response=>{
      let result = response.data.pricingDistribution
      setPieChartData(_.reverse(result))
      setIsLoading(false)
     }).catch(err=>{
      console.log(err)
      setIsLoading(false)
    })   
            

   },[storeId,selectedCategory,gridSelection,filterType,skuType])

   useEffect(()=>{
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuImage = null
    })
   },[skuType])

   useEffect(()=>{
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": sortCol.perPage,
      "pageNbr": 1,
      "sortColumn": sortCol.colIndex,
      "distType": null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
   fetchSkuSummary(gridPayload)
   },[storeId,filterType,skuType])

   const fetchSkuSummary = (payload = {}) => {
    let gridPayload = payload;
    setGridData(draft=>{
      draft.isLoading = true
    })
    utilities.setLoader(true)
    restUtils.postData(storePricingDistSummary, gridPayload).then(response=>{
      const data = response.data
      setGridData(draft=>{
        draft.isLoading = false
      })
      setGridData(draft=>{
        draft.rowData = data.skuPrices;
        draft.totalData = data.skuTotal;
        draft.itemCount = data.skuTotal.totalCount;
      })
      setPagination(draft=>{
        draft.totalPages =  data.skuTotal.pageCount
      })
      utilities.setLoader(false)
     }).catch(err=>{
      console.log(err)
      setGridData(draft=>{
        draft.isLoading = false
      })
      utilities.setLoader(false)
     })
   }
   
  const onPageChange = e => {
    const text = e.currentTarget.text
    let page = ''
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuValue=null;
      draft.skuImage= null;
      draft.deptCode= null;
    })
    if(!isNaN(parseInt(e.currentTarget.text))) {
       setPagination(draft=>{
          draft.currentPage = parseInt(text);
       })
       page = parseInt(text)
    }
    if (e.currentTarget.text === 'Next') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage < draft.totalPages
          ? parseInt(draft.currentPage) + 1
          : draft.currentPage;
       })
       page = pagination.currentPage < pagination.totalPages
       ? parseInt(pagination.currentPage) + 1
       : pagination.currentPage
    }
    if (e.currentTarget.text === 'Prev') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
       })
       page =  pagination.currentPage === 1 ? 1 : pagination.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
       setPagination(draft=>{
          draft.currentPage = draft.totalPages;
       })
       page = pagination.totalPages;
    }
    if (e.currentTarget.text === '«') {
      setPagination(draft=>{
       draft.currentPage = 1
    })
      page =  1;
 
    }
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": sortCol.perPage,
      "pageNbr": page,
      "sortColumn": sortCol.colIndex,
      "distType":  pieSelection.deptCode ? pieSelection.deptCode[0] : null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
   fetchSkuSummary(gridPayload)
  };
  const handleCount = (e)=>{
    const { value}= e.target
    setSortCol(draft=>{
       draft.perPage = value
    })
    setPagination(draft=>{
      draft.currentPage = 1
    })
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuValue=null;
      draft.skuImage= null;
      draft.deptCode= null;
    })
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": value,
      "pageNbr": 1,
      "sortColumn": sortCol.colIndex,
      "distType":  pieSelection.deptCode ? pieSelection.deptCode[0] : null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
   fetchSkuSummary(gridPayload)
 }
 const resetSingleSelection = ()=>{
  setGridSelection(draft=>{
    draft.isSingleSku= false;
    draft.skuValue=null;
    draft.skuImage= null;
    draft.deptCode= null;
  })
  setPieSelection(draft=>{
    draft.isSinglePie= false;
    draft.deptCode= null;
  })
 }
 const handleSort = (cell, index)=>{

  setSortCol(draft=>{
   draft.colIndex = index;
   draft.orderAsc = index === 8 ? !draft[cell.key] : draft[cell.key]
   draft[cell.key] = !draft[cell.key]
  })
   
  resetSingleSelection()
 }
 useEffect(()=>{
  if(prevSortCol !== undefined && prevSortCol !== sortCol) {
    const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": sortCol.perPage,
      "pageNbr": pagination.currentPage,
      "sortColumn": sortCol.colIndex,
      "distType":  pieSelection.deptCode ? pieSelection.deptCode[0] : null,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
    }
  fetchSkuSummary(gridPayload)
  }
 },[sortCol])
 const handlePiChartSelection = (value) =>{
  setPieSelection(draft=>{
    draft.isSinglePie = !_.isEmpty(value) ? true : false;
    draft.deptCode = !_.isEmpty(value) ? [value] : null
  })
  setPagination(draft=>{
    draft.currentPage = 1;
  })
  const gridPayload = {
    "memberNbr": storeId,	
    "filterType": filterType.name,
    "filterValues": filterType.value,
    "skuType": parseInt(skuType),
    "rowsPerPage": sortCol.perPage,
    "pageNbr": 1,
    "sortColumn": sortCol.colIndex,
    "distType": !_.isEmpty(value) ? value : null,
    "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
 }
 fetchSkuSummary(gridPayload)
 }
 const handleRowSelection = (cell,  header = false) => {
  if(!_.isEmpty(cell)) {
    setGridSelection(draft=>{
      draft.skuValue = cell.skuNbr;
      draft.deptCode = cell.dept;
      draft.isSingleSku = true;
      draft.skuImage= cell.isTvSku ? cell.skuNbr: null;
    })
    
  }else {
    if(pieSelection.isSinglePie) {
      setGridSelection(draft=>{
        draft.isSingleSku= false;
        draft.skuValue=null;
        draft.skuImage= null;
        draft.deptCode= null;
      })
    }else {
      resetSingleSelection()
    }     
  }

}
useEffect(()=>{
  setPagination(draft=>{
    draft.currentPage = 1
  })
  resetSingleSelection()
},[filterType, skuType])
  return(
    <>
    {programCode !== 'ROS' && (
      <>
    <section className='chartWrapper'>
      <div className='donutChartWrapper'>
        {isLoading && <Loader active/>}
      <Donut data = {pieChartData} handleSelection={handlePiChartSelection} isSinglePieCode = {pieSelection.deptCode} resetSelection={pieSelection.isSinglePie} gridSelection={gridSelection.isSingleSku}/>
      </div>
    </section>
    <section className='gridTableSection' id='storePricingMix'>
      {filterType.name === 'None' && skuType === '-1' ?
      <div className="gridTableWrapper">
      <div className="countPerPage">
        <div className="itemCountDiv">
           <div><p>Item Count</p>
                <h3>0</h3>
              </div>
        </div>
        <button className = 'downloadBtn' disabled>Download</button>
        </div>
      <div className="gridTable">      
      <table>
         <thead>
            <tr>
              {header['storePricingMix'].map((cell,index)=>{          
               return(
                <th key={cell.key} >               
                    {cell.name}
                </th>
               )
              })}
              {props.id === 'priceOverride' && <th> Remove</th>}
            </tr>
         </thead>
         </table>
         <div className='gridNoDataBox'>
              <h3>No Data to Display</h3>
              <p>Please make a selection</p>
         </div>
         </div> </div> :
         <>
   {gridData.isLoading && <Loader active/>}
    {gridData.rowData && (
      gridData.rowData.length > 0 ? (
        <GridTable 
        id={'storePricingMix'}
        data = {gridData}
        pagination = {pagination}
        sortCol = {sortCol}
        onPageChange={onPageChange}
        handleCount={handleCount}
        handleSort ={handleSort}
        handleSelection = {handleRowSelection}
        image={gridSelection.skuImage}
        isSkuSelection = {gridSelection.isSingleSku}
        filterType = {filterType}
        skuType = {skuType}
        defaultSort= {8}
        sortColumn= {sortCol.colIndex}
        sortOrder=  {sortCol.orderAsc ?  'asc': 'desc'}
        distType={pieSelection.deptCode ? pieSelection.deptCode[0] : null}
         />
        
      ): <p className='noDataMsg'>Insufficient data</p>) 
      }</>
      } 
    </section>
    </>
    )}
    </>
  )
}
export default StorePriceMix;
import config from "./config";

const getEnv = () => {
  const environments = {
    development: "dev",
    QA: "qa",
    production: "prod",
    uat: "uat",
  };
  return environments[config.env];
};

export const getRedirectUri = () => {
  const environments = {
    development: "https://rpo-dev.truevalue.com",
    uat: "https://rpo-uat.truevalue.com",
    production: "https://rpo.truevalue.com",
  };
  return environments[config.env];
};

export const getRpoUri = () => {
  const environments = {
    development: "https://rpo-api-dev.truevalue.com/api/rpobaseapis",
    uat: "https://rpo-api-uat.truevalue.com/api/rpobaseapis",
    production: "https://rpo-api.truevalue.com/api/rpobaseapis",
  };
  return environments[config.env];
};

export const getRedirectUriMOL = () => {
  const environments = {
    development: "https://nwa-dev.truevalue.com/",
    QA: "https://nwa-qa.truevalue.com/",
    uat: "https://nwa-uat.truevalue.com/",
    production: "https://nwa.truevalue.com/",
  };
  return environments[config.env];
};


export const getRedirectUriMOLVariablePrice = () => {
  const environments = {
    development: "https://nwa-dev.truevalue.com/content/retail-pricing",
    QA: "https://nwa-qa.truevalue.com/content/retail-pricing",
    uat: "https://nwa-uat.truevalue.com/content/retail-pricing",
    production: "https://nwa.truevalue.com/content/retail-pricing",
  };
  return environments[config.env];
};

export const getRedirectUriMOLRpo = () => {
  const environments = {
    development: "https://nwa-dev.truevalue.com/content/retail-pricing",
    QA: "https://nwa-qa.truevalue.com/content/retail-pricing",
    uat: "https://nwa-uat.truevalue.com/content/retail-pricing",
    production: "https://nwa.truevalue.com/content/retail-pricing",
  };
  return environments[config.env];
};

export const getDomain = () => {
  const domainUrls = {
    uat: "uat.membersonline.com/mol2/website/identity/connect/",
    QA: "uat.membersonline.com/mol2/website/identity/connect/",
    development: "uat.membersonline.com/mol2/website/identity/connect/",
    production: "www.membersonline.com/mol2/website/identity/connect/",
  };
  return domainUrls[config.env];
};


  export const JWKS_URI = () => {
    const environments = {
      development:
        "https://uat.membersonline.com/mol2/website/identity/.well-known/jwks",
      uat: "https://uat.membersonline.com/mol2/website/identity/.well-known/jwks",
      QA: "https://uat.membersonline.com/mol2/website/identity/.well-known/jwks",
      production:
        "https://www.membersonline.com/mol2/website/identity/.well-known/jwks",
    };
    return environments[config.env];
  };
  export const departmentUrl =
  "https://sfvvxhnc2i.execute-api.us-east-2.amazonaws.com/" + getEnv();

  export const getStoreInfo = `${getRpoUri()}/stores/store`
  export const finelinehiearchy = `${getRpoUri()}/stores/finelinehierarchy`
  export const getBarChartData = `${getRpoUri()}/Storefinancials/topnbottomPerformers`
  export const getMarginUnitsSummary = `${getRpoUri()}/storefinancials/marginandunitssummary`
  export const getPieChartData = `${getRpoUri()}/storefinancials/marginbreakdown`
  export const getStoreSkus = `${getRpoUri()}/stores/skus`
  export const getSkuSummary = `${getRpoUri()}/Storefinancials/skuSummary`
  export const getSkuSummary_WhatIfCalc = `${getRpoUri()}/priceOverride/skuPricingSummary`
  export const getSkuPricingDetail = `${getRpoUri()}/priceOverride/skuPricingDetail`
  export const updateNewPrice = `${getRpoUri()}/priceOverride/updateNewPrice`
  export const pendingFinalize = `${getRpoUri()}/priceOverride/pendingFinalize`
  export const deleteNewPrice = `${getRpoUri()}/priceOverride/deleteNewPrice`
  export const finalizeNewPrice = `${getRpoUri()}/priceOverride/finalizeNewPrice`
  export const storePricingDistribution = `${getRpoUri()}/priceChange/storePricingDistribution`
  export const storePricingDistSummary = `${getRpoUri()}/priceChange/storePricingDistSummary`
  export const priceChangeBreakdown = `${getRpoUri()}/priceChange/priceChangeBreakdown`
  export const priceChangeskuHierarchy = `${getRpoUri()}/priceChange/skuHierarchy`
  export const priceChangeSku = `${getRpoUri()}/priceChange/skus`
  export const priceChangeSummary = `${getRpoUri()}/priceChange/priceChangeSummary`
  export const getProductMasterList = `${getRpoUri()}/productMaster/itemList`
  export const addSkuProductMasterList = `${getRpoUri()}/ProductMaster/AddNewItem`
  export const deleteSkuProductMasterList = `${getRpoUri()}/ProductMaster/deleteItem`
  export const ProductMasterskuHierarchy = `${getRpoUri()}/productMaster/skuHierarchy`
  export const productMasterSku = `${getRpoUri()}/productMaster/skus`
  export const productMasterFinelineHierarchy = `${getRpoUri()}/items/itemFinelineHierarchy`
  export const productMasterUPCDetails = `${getRpoUri()}/items/itemDetail`
  export const compPricingSkuHierarchy = `${getRpoUri()}/compPricing/skuHierarchy`
  export const compPricingSku = `${getRpoUri()}/compPricing/skus`
  export const compPricingCompetitors = `${getRpoUri()}/compPricing/competitors`
  export const compPricingmarketPriceBreakdown = `${getRpoUri()}/compPricing/marketPriceBreakdown`
  export const compPricingPricingSummary = `${getRpoUri()}/compPricing/pricingSummary`
  export const compPricingPricingDetail = `${getRpoUri()}/compPricing/pricingDetail`
  export const suggPricingWeeklyAverages = `${getRpoUri()}/suggPricing/weeklyAverages`
  export const suggPricingPricingSummary = `${getRpoUri()}/suggPricing/pricingSummary`
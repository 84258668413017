import React, { useEffect, useState, useCallback } from "react";
import "../whatIfCalculator/style.scss";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductMasterList,
  deleteSkuProductMasterList,ProductMasterskuHierarchy,productMasterSku
} from "../../urlConstants";
import GridTable from "../../components/GridTable";
import restUtils from "../../utils/restUtils";
import { useImmer } from "use-immer";
import { Loader, Confirm } from "semantic-ui-react";
import _ from "lodash";
import utilities from "../../utils/utilities";
import CustomDropDown from "../common/CustomDropDown";
import { setDropDownShow } from "../../components/DropDown/dropdownSlice";
import  usePrevious from '../common/usePrevious'

export const ProductMaster = (props) => {
  const { hideDropDown } = useSelector((state) => state.DropDownReducer);
  const { selectedCategory} = useSelector(state => state.DropDownReducer)
  const [filterType, setFilterType] = useImmer({
    name: "None",
    value: [],
  });
  const [skuType, setSkuType] = useState("-1");
  const { storeId, programCode} = useSelector(
    (state) => state.SessionReducer
  );
  const [gridData, setGridData] = useImmer({
    isLoading: true,
    rowData: null,
    totalData: null,
    itemCount: "",
  });
  const [pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 10,
  });
  const [sortCol, setSortCol] = useImmer({
    colIndex: 2,
    orderAsc: false,
    perPage: 20,
  });
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showSkuType, setShowSkuType] = useState("productMaster");

  const [selectedSku, setSelectedSku] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      dispatch(setDropDownShow(false));
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
      dispatch(setDropDownShow(false));
    };
  }, []);

  const handleFilter = useCallback(
    (filterVal) => {
      setFilterType((draft) => {
        draft.name = filterVal.name;
        draft.value = filterVal.value;
      });
    },
    [filterType]
  );
  const handleSkuType = useCallback(
    (skuType) => {
      setSkuType(skuType);
    },
    [skuType]
  );
  const getProductMasterListData = () => {
    const gridPayload = {
      memberNbr: storeId,
      filterType: filterType.name,
      filterValues: filterType.value,
      skuType: parseInt(skuType),
      rowsPerPage: sortCol.perPage,
      pageNbr: pagination.currentPage,
      sortColumn: sortCol.colIndex,
      sortOrder: sortCol.orderAsc ? "asc" : "desc",
    };
    utilities.setLoader(true)
    restUtils
      .postData(getProductMasterList, gridPayload)
      .then((response) => {
        setGridData((draft) => {
          draft.isLoading = true;
        });
        const data = response.data;
        setGridData((draft) => {
          draft.isLoading = false;
        });
        setGridData((draft) => {
          draft.rowData = data.masterList?.map((el) => ({
            ...el,
            checkBox: false,
          }));
          draft.totalData = data.skuTotal.totalCount;
          draft.itemCount = data.skuTotal.totalCount;
        });
        setPagination((draft) => {
          draft.totalPages = Math.ceil(
            data.skuTotal.totalCount / sortCol.perPage
          );
        });
        utilities.setLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setGridData((draft) => {
          draft.isLoading = false;
        });
        utilities.setLoader(false)
      });
  };
  useEffect(() => {
    getProductMasterListData();
    dispatch(setDropDownShow(true));
  }, [storeId, filterType, skuType, sortCol, pagination]);

  const onPageChange = (e) => {
    const text = e.currentTarget.text;
    if (!isNaN(parseInt(e.currentTarget.text))) {
      setPagination((draft) => {
        draft.currentPage = parseInt(text);
      });
    }
    if (e.currentTarget.text === "Next") {
      setPagination((draft) => {
        draft.currentPage =
          draft.currentPage < draft.totalPages
            ? parseInt(draft.currentPage) + 1
            : draft.currentPage;
      });
    }
    if (e.currentTarget.text === "Prev") {
      setPagination((draft) => {
        draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
      });
    }
    if (e.currentTarget.text === "»") {
      setPagination((draft) => {
        draft.currentPage = draft.totalPages;
      });
    }
    if (e.currentTarget.text === "«") {
      setPagination((draft) => {
        draft.currentPage = 1;
      });
    }
  };
  const handleCount = (e) => {
    const { value } = e.target;
    setSortCol((draft) => {
      draft.perPage = value;
    });
    setPagination((draft) => {
      draft.currentPage = 1;
    });
  };

  const handleSort = (cell, index) => {
    setSortCol((draft) => {
      draft.colIndex = index;
      draft.orderAsc = index === 2 ? !draft[cell.key] : draft[cell.key];
      draft[cell.key] = !draft[cell.key];
    });
  };
  const handleSelectCheckBox = (cell, index) => {
    const value = cell.target.value;
    const data = gridData.rowData.map((ele) =>
      ele.skuNbr === value ? { ...ele, checkBox: !ele.checkBox } : ele
    );
    const sel_SKUs = data
      .filter((ele) => ele.checkBox)
      .map((ele) => ele.skuNbr);
    setGridData((draft) => {
      draft.rowData = data;
    });
    setSelectedSku(sel_SKUs);
  };

  useEffect(() => {
    setPagination((draft) => {
      draft.currentPage = 1;
    });
  }, [filterType]);

  const openAddSkuForm = () => {
    props.history.push("/addSkuToProductMaster");
  };
  const deselectAllRow = () => {
    setGridData((draft) => {
      draft.rowData = gridData.rowData.map((el) => ({
        ...el,
        checkBox: false,
      }));
    });
    setSelectedSku([]);
  };
  const handleDeleteSku = () => {
    setShowWarningPopup(true);
  };
  const closeDeleteSku = () => {
    setShowWarningPopup(false);
  };
  const confirmDeleteSku = () => {
    setShowWarningPopup(false);
    const payload = {
      memberNbr: storeId,
      skuNbrs: selectedSku,
    };
    restUtils
      .deleteData(deleteSkuProductMasterList, payload)
      .then((res) => {
        utilities.showToast("Item successfully removed from master list");
        getProductMasterListData();
      })
      .catch((err) => {
        utilities.showToast("Unable to delete Item", true);
      });
  };
  useEffect(() => {
    return () => {
      setShowSkuType("");
    };
  }, [showSkuType]);
  const handleRowSelection = () => {
    return null;
  };
  return (
    <>
      {programCode !== "ROS" && (
        <>
          {hideDropDown && (
            <CustomDropDown
              sendSkuTypeVal={handleSkuType}
              sendFilterVal={handleFilter}
              dropDownUrl={ProductMasterskuHierarchy}
              skuDropDownUrl={productMasterSku}
              showSkuType={showSkuType}
            />
          )}
          <div
            className={
              gridData.rowData
                ? "PM-container btn-position"
                : "PM-container ft-right"
            }
          >
            <button
              className="addToSKU m-lt"
              onClick={() => openAddSkuForm()}
            >
              Add
            </button>
            {!gridData.isLoading
              ? gridData.rowData && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <button
                      disabled={selectedSku.length > 0 ? false : true}
                      className="deleteAllBtn m-lt"
                      onClick={handleDeleteSku}
                    >
                      Delete
                    </button>
                    <button
                      disabled={selectedSku.length > 0 ? false : true}
                      className="deleteAllBtn m-lt"
                      onClick={deselectAllRow}
                    >
                      Deselect All
                    </button>
                  </div>
                )
              : ""}
          </div>
          <section>
            {!gridData.isLoading ? (
              gridData.rowData && gridData.rowData.length > 0 ? (
                <>
                  <section className="gridTableSection">
                    <GridTable
                      id={"productMasterList"}
                      data={gridData}
                      pagination={pagination}
                      sortCol={sortCol}
                      onPageChange={onPageChange}
                      handleCount={handleCount}
                      handleSort={handleSort}
                      handleSelection={handleRowSelection}
                      handleDeleteRow={handleSelectCheckBox}
                      defaultSort={2}
                      filterType = {filterType}
                      sortColumn= {sortCol.colIndex}
                        sortOrder=  {sortCol.orderAsc ?  'asc': 'desc'}
                    />
                  </section>
                </>
              ) : (
                <p className="noDataMsg" style={{ marginTop: "5rem" }}>
                  Insufficient data
                </p>
              )
            ) : (
              <Loader active />
            )}
          </section>
          <Confirm
            content={
              <h4 className="deleteSkuMsg">{`Do you want to delete SKUs - ${selectedSku}?`}</h4>
            }
            size="small"
            open={showWarningPopup}
            onCancel={closeDeleteSku}
            onConfirm={confirmDeleteSku}
          />
        </>
      )}
    </>
  );
};

export default ProductMaster;

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
     pieChartData :[]
}
export const chartSlice = createSlice({
    name:'dropDown',
    initialState,
    reducers :{
        setPieChartData : (state, action) => {
            state.pieChartData = action.payload
        }
    }
})

export const { setPieChartData} = chartSlice.actions

export default chartSlice.reducer
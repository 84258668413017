import React from 'react';
const Footer = (props) => {
    return (
        <div className='footerWrapper'>
            <p>This password protected site is for True Value Company Members only | @2023 True Value Company</p>
        </div>   
        
    )
}

export default Footer
import React, { Component } from "react";
import CacheBuster from 'react-cache-buster';
import ReduxToastr from "react-redux-toastr";
import "./App.css";
import { version } from "../package.json";
import "semantic-ui-css/semantic.min.css";
import { Loader } from 'semantic-ui-react';
import RenderRoutes from "./views/common/RenderRoutes";


class App extends Component {
  render() {
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loader active />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
        <div>
        <ReduxToastr
            timeOut={4000}
            newestOnTop
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
          <RenderRoutes />
          <div id="snackbar"></div>
          </div>
      </CacheBuster>


    );
  }
}
export default App
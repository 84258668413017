import React, { Component } from 'react';
import './404_error_page.css';
import {Link} from 'react-router-dom';

export default class NotFound extends Component {

  render() {
    return (
        <div className="error_404_page"> 
          <h1 className="text_center">Sorry...</h1>
          <h2>The page you are looking for could not be found.</h2>
          <h3 className="text_center">Go back to <Link to="/home"><button>Home Page</button></Link></h3>
        </div>
    );
  }
}
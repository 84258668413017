import React, {memo, useEffect} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer,Tooltip, Legend } from 'recharts';
import _ from 'lodash';
import { storePriceColors, legend} from '../common/chartUtilities'
         
const renderColorfulLegendText = (value, entry) => {
  return (
    <span>
      {legend[entry.payload.priceRange]}
    </span>
  )
};
const Donut = (props)=> { 
let data = props.data
let marginVal = 10
if(!_.isEmpty(data)) {
  marginVal = data.map(ele => ele.pricingPct).reduce((a,b)=> a+b)
  if(marginVal===0) {
    data = data.map(ele => {
      return {
        ...ele,
        pricingPct: 2
      }
    })
  }
 
}

const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, index, pricingPct } = props
  const RADIAN = Math.PI / 180;
  if((index !== 0 && pricingPct < 1.6)) {
    return null
  }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
                
    return (
      <text x={x} y={y} fill="#fff" /*textAnchor={x > cx ? 'start' : 'end'}*/ textAnchor="middle" dominantBaseline="central" className='donutText'>
        {`${pricingPct}%`}
      </text>
    );
  };
 const handlePieSelect = (e) => {
  if(props.data.length> 1) {
   const path  = document.querySelectorAll('.piePath')
   if(e.target.classList.contains('activeRow')) {
    e.target.classList.remove('activeRow')
      path.forEach((item)=>{
         item.style.opacity = '1'
       })
       props.handleSelection([])
   }
   else {
      path.forEach((item)=>{
         item.classList.remove('activeRow')
         item.style.opacity = '0.5'
       })
       e.target.classList.add('activeRow')
       e.target.style.opacity = '1'
      props.handleSelection(e.target.getAttribute('id'))
   }       
  }
 }
 useEffect(()=>{
  if(!props.resetSelection || props.gridSelection) {
    const path  = document.querySelectorAll('.piePath')
    path.forEach((item)=>{
      item.classList.remove('activeRow')
      item.style.opacity = '1'
    })
  }

 },[props.resetSelection, props.gridSelection])

 useEffect(()=>{
  if(!_.isEmpty(props.isSinglePieCode)) {
    const id = document.getElementById(`${props.isSinglePieCode[0]}`)
    id.classList.add('activeRow')
  }
 })

    return (
        <div style={{width:'100%', height:'100%'}}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
              <Legend
              height={36}
              iconType="rect"
              layout="vertical"
              verticalAlign="middle"
              iconSize={20}
              padding={2}
              formatter={renderColorfulLegendText}
        />
          <Pie
            data={data}
            cx= '40%'
            cy='50%'
            labelLine={false}
            isAnimationActive={false}
            animationDuration={400}
            animationEasing={'ease-out'}
            label={renderCustomizedLabel}
            innerRadius={65}
            outerRadius={120}
            fill="#000"
            dataKey="pricingPct"
            blendStroke
          >
            {data.map((entry, index) => {
              return (
                  <Cell key={entry.pricingPct} className="piePath" id={entry.priceRange} onClick={handlePieSelect} stroke='#504f4f' fill={storePriceColors[entry.priceRange]} style={{
                    filter: `drop-shadow(0px 0px 2px #000)`
                  }}/>
              )
            })}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
      </div>
    );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip dountTooltip">
        <p className='marginAmount'>{`Range: ${legend[payload[0].payload.priceRange]}`}</p>
        <p className='marginAmount'>{`Percentage: ${payload[0].payload.pricingPct}%`}</p>
      </div>
    );
  }

  return null;
};

export default memo(Donut)
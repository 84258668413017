function elasCap(pElas, typeElas, natElas, maxElas) {
	let minP;
    let r = pElas;
    if (typeElas === "asp") {
    	minP = -50;
    }
    else {
    	minP = -3.18;
    }
    if(pElas >= maxElas) {
    	let pElt = Math.min(4, Math.abs(pElas));
        let pElasAdj = maxElas + (-0.5 * (pElt/4));
        if(natElas < 0 && typeElas !== "asp") {
        	r = Math.min(natElas, pElasAdj);
        }
        else {
        	r = Math.min(pElasAdj, pElas);
        }
    }
    if(pElas < minP) {
    	r = minP;
    }
    return r;
}
function compElas(elas) {
	let elasQ = elas/2;
    let elasAdj = Math.max(-1.1, elasQ);
    return elasAdj;
}
export function marginAnalysis(userPrice, currentPrice, avgSales, costSel, priceElasDefault, priceElasNat, priceElas, actualCost) {
    let price = Number.parseFloat(userPrice > 0 ? userPrice : currentPrice);
    let elas = elasCap(priceElasDefault, "default", priceElasNat, -1.5);
    let aspElas = elasCap(priceElas, "asp", priceElasNat, -1.5);
    elas = Math.max(elas, aspElas);
    let compPriceAdj = (price/currentPrice - 1);
    let compPriceAdj1 = (currentPrice * (1 + compPriceAdj)).toFixed(2);
    let compSens = compElas(elas);
    compSens = Math.max(compSens, elas);
    let compAdj = Math.pow(compPriceAdj1, compSens)/Math.pow(currentPrice, compSens);
    compAdj = Math.min(1.1, compAdj);
    let salesChange = Math.pow(price, elas)/Math.pow(currentPrice, elas);
    let salesChange1 = salesChange * compAdj;
    let salesChangePct = (100 * (salesChange1 - 1)).toFixed(2);
    let costNew = costSel > 0 ? costSel : actualCost;
    let newGPDollars = (price - costNew) * avgSales * salesChange1;
    let gpDollars = Math.max(0, (currentPrice - actualCost) * avgSales);
    let beUnits = gpDollars/(price - costNew);
    let bePct = (100 * (beUnits/avgSales - 1)).toFixed(2);
    let mtbInit;
    if(gpDollars === 0){
    	mtbInit = "Unsolvable";
    }
    else {
    	mtbInit = (newGPDollars - gpDollars).toFixed(2);
    }
    let priceChangePct = (100 * (userPrice - currentPrice) / currentPrice).toFixed(2);
    let result = new Array(4);
    result[0] = priceChangePct;
    result[1] = bePct;
    result[2] = salesChangePct;
    result[3] = mtbInit;
    return result;    
}
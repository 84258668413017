
import React, { useEffect, useState, useCallback } from 'react';
import StorePriceMix from './StorePriceMix'
import PriceView from './PriceView'
import "./style.scss"
import { useDispatch, useSelector } from 'react-redux';
import { setDropDownShow} from '../../components/DropDown/dropdownSlice'
import {priceChangeskuHierarchy, priceChangeSku} from '../../urlConstants'
import { useImmer } from 'use-immer';
import _ from 'lodash';
import CustomDropDown from '../common/CustomDropDown';
const PriceChange = (props) =>{
    const [ defaultView, setDefaultView] = useState(0)
    const { hideDropDown} = useSelector(state => state.DropDownReducer)
    const { programCode } = useSelector(state => state.SessionReducer)
    const [filterType, setFilterType] = useImmer({
        name: 'None',
        value:[]
    })
    const dispatch = useDispatch();
    const handleView =(e) =>{
        const isChecked = e.target.checked
        if(isChecked) {
          setDefaultView(1)
          dispatch(setDropDownShow(true))
        }else {
          setDefaultView(0)
          dispatch(setDropDownShow(false))
        }
      }
      useEffect(() => {
        const handleTabClose = event => {
          event.preventDefault();
          dispatch(setDropDownShow(false))
        };
    
        window.addEventListener('beforeunload', handleTabClose);
    
        return () => {
          window.removeEventListener('beforeunload', handleTabClose);
          dispatch(setDropDownShow(false))
        };
      }, []);

      const handleFilter = useCallback((filterVal) => {
        setFilterType(draft=>{
          draft.name =filterVal.name;
          draft.value=filterVal.value;
        })
      }, [filterType]);
      return (
        <>
        {
            hideDropDown && (
              <CustomDropDown 
              sendFilterVal = {handleFilter}
              dropDownUrl = {priceChangeskuHierarchy}
              skuDropDownUrl = {priceChangeSku}
              />
            )
        }
        { programCode !== 'ROS' && (
          <section className='priceChangeSection'>
          <div className='priceChartWrapper'>
              <div className='toggleBtn'>
             <span>Store Pricing Mix View</span>
             <span><input type="checkbox" id="switch" onChange={handleView}/><label htmlFor="switch">Toggle</label></span>
             <span>Price Change View</span>
         </div>
          </div>
          {!parseInt(defaultView) ? 
             <StorePriceMix/>:
             <PriceView filterType={filterType}/> }
         </section>
        )}       
        
        </>
      )
}

export default PriceChange
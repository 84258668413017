import { persistor } from '../../configureStore';
import {  JWKS_URI } from '../../urlConstants';
import Axios from 'axios';

export const LOGIN = 'LOGIN_ACTION';
export const AUTHORIZATION = 'AUTHORIZATION';
export const GET_SECRET = 'GET_SECRET';
export const LOGOUT = 'LOGOUT';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const CHANGE_STORE ='CHANGE_STORE'
export const SET_AUTHENTICATION_LOADER = 'SET_AUTHENTICATION_LOADER'

export const login = (username, storeId,memberName, programCode, multiStoreGroup, isAuthenticated, viewOnly, storeDetails) => {
  return dispatch => {
    dispatch({
      type: LOGIN,
      payload: {
        username,
        storeId,
        memberName,
        programCode,
        multiStoreGroup,
        isAuthenticated,
        viewOnly,
        storeDetails        
      }
    });
  };
};

export const setAuthentication = isAuthenticated => ({
  type: SET_AUTHENTICATION,
  payload: {
    isAuthenticated
  }
});
export const setAuthenticationLoader = isAuthenticated => ({
  type: SET_AUTHENTICATION_LOADER,
  payload: {
    isAuthenticated
  }
});
export const authorizeUser = (id_token, access_token, expires_at) => ({
  type: AUTHORIZATION,
  payload: {
    id_token,
    access_token,
    expires_at
  }
});
export const changeStore = (user)=>({
  type: CHANGE_STORE,
  payload: {
    user
  }
});
export const getSecretKey = (access_token)=>{
  const jwks_url = JWKS_URI();
  return dispatch => {
    Axios.get(jwks_url)
      .then(response=>{
        dispatch({
          type:GET_SECRET,
          payload:response.data.keys[0].x5c[0]
        })
      })
  }
}
export const logout = () => {
  return dispatch => {
    localStorage.clear();
    persistor.purge().then(() => {
      return dispatch({ type: LOGOUT });
    });
  };
};

import React, { useEffect, useState, useCallback } from 'react';
import "./style.scss"
import { useDispatch, useSelector } from 'react-redux';
import { setDropDownShow} from '../../components/DropDown/dropdownSlice'
import {compPricingSkuHierarchy, compPricingSku, compPricingmarketPriceBreakdown, compPricingPricingSummary, compPricingCompetitors} from '../../urlConstants'
import { useImmer } from 'use-immer';
import restUtils from '../../utils/restUtils';
import _ from 'lodash';
import CustomDropDown from '../common/CustomDropDown';
import StackedBarChart from './StackedBarChart';
import { Loader, Modal } from 'semantic-ui-react';
import utilities from '../../utils/utilities';
import GridTable from '../../components/GridTable';
import PriceDetails from './PriceDetails';
import  usePrevious from '../common/usePrevious'
import { header} from '../../components/GridTable/gridHeader'
const CompetitorPrice = (props) =>{
  const { storeId } = useSelector(state => state.SessionReducer)
  const { programCode } = useSelector(state => state.SessionReducer)
  const filterType1  = JSON.parse(localStorage.getItem('persist:dropdown'))
  const { name, value} = JSON.parse(filterType1['filterType']);
    const [filterType, setFilterType] = useImmer({
        name:  "None",
        value: [],
        competitor: []
    })
    const [competitorData, setCompetitorData] =  useState([])
    const [ pieSelection, setPieSelection] =  useImmer({
      isSinglePie : false,
      compId:null,
      compRangeId:null,
  })
  const [gridSelection, setGridSelection] = useImmer({
    isSingleSku: false,
    skuValue:null,
    skuImage: null
  })
  const [ gridData, setGridData] = useImmer({
    isLoading:true,
    rowData :null,
    totalData:null,
    itemCount:'' ,
  })
  const [ pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 10
 })
  const [ sortCol, setSortCol] = useImmer({
    colIndex:17,
    orderAsc:false,
    perPage: 20,
  })
  const [ initLoad, setInitLoad] = useState(false)
  const prevSortCol = usePrevious(sortCol)
  const dispatch = useDispatch();
  const [ chartData, setChartData] = useImmer({
      isLoading : false,
      data:[]
  })
  const [ isLoading, setIsLoading] = useState(false)
  const [ priceDetails, setPriceDetails] = useImmer({
      showPopup: false,
      sku:null
  })
  useEffect(()=>{
    if(filterType.name !== 'None' || filterType.competitor.length > 0) {
      setInitLoad(true)
    }
  },[filterType])
      useEffect(() => {
        const handleTabClose = event => {
          event.preventDefault();
          dispatch(setDropDownShow(false))
        };
    
        window.addEventListener('beforeunload', handleTabClose);
    
        return () => {
          window.removeEventListener('beforeunload', handleTabClose);
          dispatch(setDropDownShow(false))
        };
      }, [dispatch]);
      useEffect(()=>{
          restUtils.getData(`${compPricingCompetitors}`).then(res=>{  
          setCompetitorData( _.orderBy(res.data.competitors, ['compName'], ['asc']))
          }).catch((err)=>{
           console.log(err)
          })
      },[])
      useEffect(()=>{
        if(initLoad && (filterType.name !== 'None' || filterType.competitor.length > 0)) {
        const payLoad = {
          "memberNbr": storeId,	
          "filterType":  gridSelection.isSingleSku ? 'Sku' :filterType.name,
          "filterValues":  gridSelection.isSingleSku ? [gridSelection.skuValue.toString()] : !_.isEmpty(filterType.value) ? filterType.value :  null,
          "compId": !_.isEmpty(filterType.competitor) ? filterType.competitor :  null
        }
        setChartData(draft=>{
          draft.isLoading = true;
        })
        utilities.setLoader(true)
        restUtils.postData(compPricingmarketPriceBreakdown, payLoad).then(response=>{
          let result = _.map(response.data.compPriceBreakdown,(comp)=>{
            let obj = comp;
            _.map(comp.compRange,(ele,index)=>{
                 obj = {
                  ...obj,
                  [`compRangeId_${ele.compRangeId}`] : ele.compRangeId,
                  [`compRangePct_${ele.compRangeId}`] : ele.compRangePct,
                  [`compRangeName_${ele.compRangeId}`]: ele.compRangeName,
                 }
            })
            return obj;
          })
          let data  = result.filter(ele => ele.compRangePct_3 !="100");
          setChartData(draft=>{
            draft.data = data;
            draft.isLoading = false;
          })
          utilities.setLoader(false)
         }).catch(err=>{
          console.log(err)
          setChartData(draft=>{
            draft.isLoading = false;
          })
          utilities.setLoader(false)
          }) 
        }
      },[storeId, initLoad, gridSelection, filterType])
      useEffect(()=>{
        const payload = {
         "memberNbr": storeId,	
         "filterType": filterType.name,
         "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
         "compId": !_.isEmpty(filterType.competitor) ? filterType.competitor :  null,
         "compRangeId":null,
         "rowsPerPage": sortCol.perPage,
         "pageNbr": 1,
         "sortColumn": sortCol.colIndex,
         "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
       }
        fetchSkuSummary(payload)
       },[storeId,initLoad,filterType]) 
    const fetchSkuSummary = (payload = {}) =>{
      if(initLoad && filterType.name !== 'None' || filterType.competitor.length > 0) {
      let gridPayload = payload;
      setIsLoading(true) 
      restUtils.postData(compPricingPricingSummary, gridPayload).then(response=>{
       const data = response.data
       setGridData(draft=>{
         draft.rowData = data.tvSkuCompPrices;
         draft.totalData = data.skuTotal;
         draft.itemCount = data.skuTotal.totalCount;
         draft.isLoading = false
       })
       setPagination(draft=>{
         draft.totalPages = data.skuTotal.pageCount
       })
       setIsLoading(false) 
      }).catch(err=>{
       console.log(err)
       setGridData(draft=>{
         draft.isLoading = false
       })
       setIsLoading(false) 
      })
    }
    }
      const handleFilter = useCallback((filterVal) => {
        setFilterType(draft=>{
          draft.name =filterVal.name;
          draft.value=filterVal.value;
          draft.competitor = filterVal.competitor
        })
      }, [filterType]);
      const onPageChange = e => {
        const text = e.currentTarget.text
        let page = ''
        setGridSelection(draft=>{
          draft.isSingleSku= false;
          draft.skuValue=null;
          draft.skuImage= null;
        })
        if(!isNaN(parseInt(e.currentTarget.text))) {
           setPagination(draft=>{
              draft.currentPage = parseInt(text);
           })
           page = parseInt(text)
        }
        if (e.currentTarget.text === 'Next') {
           setPagination(draft=>{
              draft.currentPage = draft.currentPage < draft.totalPages
              ? parseInt(draft.currentPage) + 1
              : draft.currentPage;
           })
           page = pagination.currentPage < pagination.totalPages
           ? parseInt(pagination.currentPage) + 1
           : pagination.currentPage
        }
        if (e.currentTarget.text === 'Prev') {
           setPagination(draft=>{
              draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
           })
           page =  pagination.currentPage === 1 ? 1 : pagination.currentPage - 1;
        }
        if (e.currentTarget.text === '»') {
           setPagination(draft=>{
              draft.currentPage = draft.totalPages;
           })
           page = pagination.totalPages;
        }
        if (e.currentTarget.text === '«') {
          setPagination(draft=>{
           draft.currentPage = 1
        })
          page =  1;
     
        }
        const payload = {
          "memberNbr": storeId,	
          "filterType": filterType.name,
          "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
          "compId": pieSelection.isSinglePie ? pieSelection.compId : !_.isEmpty(filterType.competitor) ? filterType.competitor :  null,
           "compRangeId":pieSelection.compRangeId,
          "rowsPerPage": sortCol.perPage,
          "pageNbr": page,
          "sortColumn": sortCol.colIndex,
          "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
       }
        fetchSkuSummary(payload)
      };
      const handleCount = (e)=>{
        const { value}= e.target
        setSortCol(draft=>{
           draft.perPage = value
        })
        setPagination(draft=>{
          draft.currentPage = 1
        })
        setGridSelection(draft=>{
          draft.isSingleSku= false;
          draft.skuValue=null;
          draft.skuImage= null;
        })
        const payload = {
          "memberNbr": storeId,	
          "filterType": filterType.name,
          "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
          "compId": pieSelection.isSinglePie ? pieSelection.compId : !_.isEmpty(filterType.competitor) ? filterType.competitor :  null,
           "compRangeId":pieSelection.compRangeId,
          "rowsPerPage": value,
          "pageNbr": 1,
          "sortColumn": sortCol.colIndex,
          "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
       }
        fetchSkuSummary(payload)
     }
     const resetSingleSelection = ()=>{
      setGridSelection(draft=>{
        draft.isSingleSku= false;
        draft.skuValue=null;
        draft.skuImage= null;
      })
      setPieSelection(draft=>{
        draft.isSinglePie= false;
        draft.compId =  null
        draft.compRangeId =null
      })
     }
     const handleSort = (cell, index)=>{
      setSortCol(draft=>{
       draft.colIndex = index;
       draft.orderAsc = index === 17 ? !draft[cell.key] : draft[cell.key]
       draft[cell.key] = !draft[cell.key]
      })
      resetSingleSelection()
     }
     useEffect(()=>{
      if(prevSortCol !== undefined && prevSortCol !== sortCol) {
      const payload = {
          "memberNbr": storeId,	
          "filterType": filterType.name,
          "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
          "compId": pieSelection.isSinglePie ? pieSelection.compId : !_.isEmpty(filterType.competitor) ? filterType.competitor :  null,
            "compRangeId":pieSelection.compRangeId,
          "rowsPerPage": sortCol.perPage,
          "pageNbr": pagination.currentPage,
          "sortColumn": sortCol.colIndex,
          "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
        }
          fetchSkuSummary(payload)
        }
     },[sortCol])
     const handlePiChartSelection = (val) =>{
      const value = !_.isEmpty(val) ? val.split('_') : null
      setPieSelection(draft=>{
        draft.isSinglePie = !_.isEmpty(value) ? true : false;
        draft.compId = !_.isEmpty(val) ? [value[0]] : null
        draft.compRangeId = !_.isEmpty(val) ? value[1] : null
      })
      setPagination(draft=>{
        draft.currentPage = 1;
      })
      const payload = {
      "memberNbr": storeId,	
       "filterType": filterType.name,
       "filterValues":!_.isEmpty(filterType.value) ? filterType.value :  null,
       "compId": !_.isEmpty(value) ? !_.isEmpty(val) ? [value[0]] : null : !_.isEmpty(filterType.competitor) ? filterType.competitor :  null,
       "compRangeId":!_.isEmpty(val) ? value[1] : null,
       "rowsPerPage": sortCol.perPage,
       "pageNbr": 1,
       "sortColumn": sortCol.colIndex,
       "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
     }
      fetchSkuSummary(payload)
    }
     const handleRowSelection = (cell,  header = false) => {
      if(!_.isEmpty(cell)) {
        setGridSelection(draft=>{
          draft.skuValue = cell.skuNbr;
          draft.isSingleSku = true;
          draft.skuImage= cell.isTvSku ? cell.skuNbr: null;
        })
        
      }else {
          resetSingleSelection()
      }
    
    }
    const closePopUp = ()=>{
      setPriceDetails(draft=>{
        draft.showPopup = false
        draft.sku = null;
      })
      setGridSelection(draft=>{
        draft.isSingleSku= false;
        draft.skuValue=null;
        draft.skuImage= null;
      })
    }
    const handlePopup = (val) =>{
      setPriceDetails(draft=>{
        draft.showPopup = true;
        draft.sku = val;
      })
      
    }
    useEffect(()=>{
      setPagination(draft=>{
        draft.currentPage = 1
      })
      resetSingleSelection()
    },[filterType])
      return (
        <>
         {isLoading && <div className='commonLoader'><Loader active/></div>}
         <Modal closeIcon
            className='modelPopup priceDetailPopup'
            onClose = {closePopUp}
            closeOnDimmerClick={false}
            open={priceDetails.showPopup}>
              <Modal.Content className=''>
                <PriceDetails
                sku = {priceDetails.sku}
                compId= {filterType.competitor}
               /></Modal.Content>             
          </Modal>
          { programCode !== 'ROS' && (
            <>
            <CustomDropDown 
            sendFilterVal = {handleFilter}
            dropDownUrl = {compPricingSkuHierarchy}
            skuDropDownUrl = {compPricingSku}
            page ='competitorPrices'
            competitorData = {competitorData}
             />   
             <section className='stackedBarChartWrapper'>
             {filterType.name !== 'None' || filterType.competitor.length > 0 ? (
              <div className='stackedBarChart'>
              {chartData.data.length > 0 &&  <StackedBarChart data={chartData.data} handleSelection = {handlePiChartSelection} />}                 
              </div>
             ):<div className='noSelectionMsg'><p>Please make a selection from the dropdown or from the table to active the graph.</p></div>}
              
              </section> 
              <section className='gridTableSection'>
              {filterType.name === 'None' && filterType.competitor.length === 0 || gridData.rowData === null ?
            <div className="gridTableWrapper">
            <div className="countPerPage">
              <div className="itemCountDiv">
                <div><p>Item Count</p>
                      <h3>0</h3>
                    </div>
              </div>
              </div>
      <div className="gridTable">      
      <table>
         <thead>
            <tr>
              {header['competitorPrices'].map((cell,index)=>{          
               return(
                <th key={cell.key} >               
                    {cell.name}
                </th>
               )
              })}
            </tr>
         </thead>
         </table>
         <div className='gridNoDataBox'>
              <h3>No Data to Display</h3>
              <p>Please make a selection</p>
         </div>
         </div> </div> :
         <>
                {!gridData.isLoading ? (
                  gridData.rowData && (
                    gridData.rowData.length > 0 ? (
                      <GridTable 
                      id={'competitorPrices'}
                      data = {gridData}
                      pagination = {pagination}
                      sortCol = {sortCol}
                      onPageChange={onPageChange}
                      handleCount={handleCount}
                      handleSort ={handleSort}
                      handleSelection = {handleRowSelection}
                      image={gridSelection.skuImage}
                      isSkuSelection = {gridSelection.isSingleSku}
                      defaultSort= {17}
                      handlePopup = {handlePopup}
                      filterType = {filterType}
                      sortColumn= {sortCol.colIndex}
                      sortOrder=  {sortCol.orderAsc ?  'asc': 'desc'}
                        priceChangeType = {pieSelection.dept ? pieSelection.dept[0] : null}
                      />
                      
                      ): <p className='noDataMsg'>Insufficient data</p>)
                      ):<Loader active />}  
                      </>
                }
                </section>   
          </>
        )}
        </>
        )
  }

export default CompetitorPrice
import React, {memo, useEffect} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer,Tooltip } from 'recharts';
import utilities from '../../utils/utilities';
import _ from 'lodash';
    
const RenderCustomizedLabelLine = function (props) {
  const { index, marginPercent} = props
  if((index !== 0 && marginPercent < 2)) {
    return null
  }
//return (props.value !== 0 ? <path stroke={props.stroke} d={`M${props.points[0].x},${props.points[0].y}L${props.points[1].x},${props.points[1].y}`} className="customized-label-line" /> : <polyline stroke={props.stroke} fill="none" />)
return (<path stroke={props.stroke} d={`M${props.points[0].x},${props.points[0].y}L${props.points[1].x},${props.points[1].y}`} className="customized-label-line" />)        
}
            

const DepartmentPieChart = (props)=> { 
let data = props.data
let marginVal = 10
if(!_.isEmpty(data)) {
  marginVal = data.map(ele => ele.marginPercent).reduce((a,b)=> a+b)
  if(marginVal===0) {
    data = data.map(ele => {
      return {
        ...ele,
        marginPercent: 2
      }
    })
  }
 
}

const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, index, marginPercent } = props
  if((index !== 0 && marginPercent < 2)) {
    return null
  }
    const RADIAN = Math.PI / 180;
                // eslint-disable-next-line
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                // eslint-disable-next-line
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                // eslint-disable-next-line
                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                let text =data[index].deptName
                if(text.split('').length > 18) {
                  text = text.slice(0, 18)+'...'
                }
                return (
                  <text
                  fontSize={7.3}  
                   x={x}
                   y={y}
                   fill="#8884d8"
                   textAnchor={x > cx ? "start" : "end"}
                   dominantBaseline="central"
                   className={`text_${index}`}>
                   {text+` ${marginVal === 0 ? 0 : marginPercent}%`}                   
                 </text>
                  
                );
  };
 const handlePieSelect = (e) => {
  if(props.data.length> 1) {
   const path  = document.querySelectorAll('.piePath')
   if(e.target.classList.contains('activeRow')) {
    e.target.classList.remove('activeRow')
      path.forEach((item)=>{
         item.style.opacity = '1'
       })
       props.handleSelection([])
   }
   else {
      path.forEach((item)=>{
         item.classList.remove('activeRow')
         item.style.opacity = '0.3'
       })
       e.target.classList.add('activeRow')
       e.target.style.opacity = '1'
      props.handleSelection(e.target.getAttribute('id'))
   }       
  }
 }
 useEffect(()=>{
  if(!props.resetSelection || props.gridSelection) {
    const path  = document.querySelectorAll('.piePath')
    path.forEach((item)=>{
      item.classList.remove('activeRow')
      item.style.opacity = '1'
    })
  }

 },[props.resetSelection, props.gridSelection])
 useEffect(()=>{
  if(!_.isEmpty(props.isSinglePieCode)) {
    const id = document.getElementById(`${props.isSinglePieCode[0]}`)
    id.classList.add('activeRow')
  }
 })

    return (
        <div style={{width:'100%', height:'100%'}}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400} className="pieChartContainer">
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={RenderCustomizedLabelLine}
            isAnimationActive={false}
            animationDuration={400}
            animationEasing={'ease-out'}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#000"
            dataKey="marginPercent"
          >
            {data.map((entry, index) => {
              return (
                  <Cell key={entry.deptCode} className="piePath" id={entry.deptCode} onClick={handlePieSelect}stroke = {props.colors[entry.deptCode]}fill={props.colors[entry.deptCode]} />
              )
            })}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
      </div>
    );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].payload.deptName}`}</p>
        <p className='marginAmount'>{`Margin: $${utilities.formatNumber(payload[0].payload.marginDollars)}`}</p>
        <p className='marginAmount'>{`Percentage:${payload[0].payload.marginPercent}%`}</p>
      </div>
    );
  }

  return null;
};

export default memo(DepartmentPieChart)
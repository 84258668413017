import React, { memo } from 'react';
import { LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { suggPriceLegend, getMonth} from '../common/chartUtilities'
const renderColorfulLegendText = (value, entry) => {
  return (
    <span>
      {suggPriceLegend[value]}
    </span>
  )
};

const StackedBarChart = (props) =>{
    const data = props.data
    const handleActiveDot = (payload) =>{
      props.handleSelection(payload.payload.weekStartDate)
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="weekStartDate" tickLine = {false }interval={2}  tick={<CustomizedGroupTick data={data}/>}/>
            <YAxis  yAxisId={0}  label={{ value: 'Total Units', angle: -90, position: 'insideLeft'}} dataKey='totalUnits' interval={0}  orientation="left" allowDataOverflow={true} />
            <YAxis yAxisId={1} interval={0} orientation="right" allowDataOverflow={true}/>
            <Legend iconType ='circle' formatter={renderColorfulLegendText}/>
            <Tooltip content={<CustomTooltip />}/>
            <Line yAxisId={0} dataKey='totalUnits' blendStroke  stackId="a" stroke="#090" fill="#090"   dot={false}activeDot={{ onClick: (event, payload) => handleActiveDot(payload) }}>
          </Line>
            <Line yAxisId={1} type="linear" dataKey='avgAsp' blendStroke stroke="#fe0000" dot={false} activeDot={{ onClick: (event, payload) => handleActiveDot(payload) }}>
            </Line>
            <Line yAxisId={1} type="linear" dataKey='avgFillRateIndex' blendStroke  stroke="#f99803" dot={false} activeDot={{ onClick: (event, payload) => handleActiveDot(payload) }}>
            </Line>
            <Line yAxisId={1} type="linear" dataKey='avgRegPrice' blendStroke  stroke="#0065cd" dot={false} activeDot={{ onClick: (event, payload) => handleActiveDot(payload) }}>
            </Line>
            <Line yAxisId={1} type="linear" dataKey='avgWholesaleCost' blendStroke  stroke="#000" dot={false} activeDot={{ onClick: (event, payload) => handleActiveDot(payload)}}>
            </Line>          
          </LineChart>
        </ResponsiveContainer>
      );
}
const CustomizedGroupTick = (props) => {
  const { index, x, y, payload } = props;
  const text = payload.value.split('-')
  return (
    <g>
      <g>
        <text x={index ===0 ? x-5 : x-20} y={y+10} fontSize={10}>
        {getMonth[text[1]]+' '+ text[0]}
        </text>
      </g>
    </g>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip stackedBarCustomToolTip">
         <h4>Date: {payload[0].payload.weekStartDate}</h4>
         <div>
         {payload.map(ele=>{
          return (         
            <p  key = {ele.dataKey} style={{color:ele.stroke}}>{suggPriceLegend[ele.dataKey]}: {ele.value}</p>
          )
         })}
         </div>
      </div>
    );
  }

  return null;
};
export default memo(StackedBarChart);

import React, { useState } from 'react';
import { connect ,useSelector, useDispatch} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'semantic-ui-react';
import { getStoreInfo, finelinehiearchy } from '../../urlConstants'
import restUtils from '../../utils/restUtils';
import { utilities } from '../../utils/utilities'
import {login} from '../../views/Login/actions'
import { setDropDownData } from '../DropDown/dropdownSlice'

const ChangeStore = (props) => {
    const { userName, viewOnly, multiStoreGroup, storeDetails, programCode } = useSelector(state => state.SessionReducer)
    const [ selectedStore, setSelectedStore] = useState('')
    const dispatch = useDispatch()
    function handleSubmit() {
        if(selectedStore==='') {
            return true
        }
        utilities.setLoader(true)  
    restUtils.getData(`${getStoreInfo}/${selectedStore}`).then(response=>{
        const storeDetails = response.data
        props.login(
            utilities.emptyForNull(userName),
            storeDetails.memberNbr,
            storeDetails.memberName,
            utilities.emptyForNull(storeDetails?.programCode),
            utilities.emptyForNull(storeDetails?.multiStoreGroup),
            true,
            viewOnly,
            storeDetails      
          );
          dispatch(setDropDownData(`${finelinehiearchy}/${selectedStore}`))
          props.handlePopup()
          utilities.setLoader(false)  
    }).catch(err=>{
        utilities.setLoader(false)  
    })
    }
    return (
        <>
        <div className='changeStoreContainer'>
        <h3>Change Store</h3>
        <Form>
            <Form.Field>
                <div>
                    <div className='changeStoreFields'>
                    {!multiStoreGroup ? (
                        <input disabled  value={storeDetails.memberNbr}/>
                    ):
                    <select name="drinks"  defaultValue ={selectedStore} onChange={e=>setSelectedStore(e.target.value)}>
                    <option value={selectedStore} disabled={false} hidden>Select Store</option>                     
                        {props.storeDetails.storeGroup.map(store=>{
                            if(props.storeId !== store.memberNbr) {
                                return(
                                    <option disabled={programCode === 'ROS' ? true : null} key={store.memberNbr} value={store.memberNbr}>{store.memberNbr}</option>
                                    ) 
                            }  
                            return null                         
                        })}
                    </select>}
                    <button className='' onClick={handleSubmit}>GO</button>
                    </div>
                </div>
            </Form.Field>
        </Form>
        </div>
     </>
    )
}
const mapStateToProps = state => {
    return {
      isAuthenticated: state.SessionReducer.isAuthenticated,
      secretKey:state.SessionReducer.secretKey,
      access_token:state.SessionReducer.access_token,
    };
  };
  function mapDispatchToProps(dispatch) {
    return Object.assign(
      { dispatch },
      bindActionCreators(
         {  
          login,
  },
  dispatch
  )
  );
  }
  export default connect(mapStateToProps, mapDispatchToProps)(ChangeStore);
  
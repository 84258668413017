import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { restUtils } from '../../utils/restUtils';
import utilities from '../../utils/utilities';

const initialState = {
    finelineHierarchy : [],
    catgoryData:[],
    selectedCategory:[],
    classData:[],
    selectedClass:[],
    subClassData:[],
    seletedSubClass:[],
    skuType:'-1',
    sku:[],
    seletedSku:[],
    hideDropDown : false,
    filterType:{
      name:"None",
      value:null
    }
}
  export const loadUserData = createAsyncThunk(
    'load/userData',
    async(url,  { rejectWithValue }) => {
        try {
            const response =  await restUtils.getData(url)
            return response
          } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
          }
    }
  )
export const dropdownSlice = createSlice({
    name:'dropDown',
    initialState,
    reducers :{
        finelineHierarchy:(state, action) =>{
           let dropDownData = action.payload.finelineHierarchy.length > 0 && action.payload.finelineHierarchy.map(ele=>{
            return {
              ...ele,
              'deptName':ele.deptName.toLowerCase(),
              'className':ele.className.toLowerCase(),
              'subclassName':ele.subclassName.toLowerCase(),
            }
           })
            state.finelineHierarchy = dropDownData
        },
        setSelectedCategory:(state, action)=> {
            state.selectedCategory = action.payload
            const values = action.payload.map(item => item.value)
            const data = state.finelineHierarchy.filter((ele) => {
                if(values.includes(ele.deptCode)) {
                  return ele
                }
                return null
              })
            state.classData = data
        },
        setSelectedClass:(state, action) =>{
            state.selectedClass = action.payload
            const values = action.payload.map(item => item.uniqCode)
            const data = state.finelineHierarchy.filter(ele=>{
                if(values.includes(ele.uniqClassId)) {
                  return ele
                }
                return null
              })
            state.subClassData = data
        },
        setSeletedSubClass:(state, action) =>{
            state.seletedSubClass = action.payload
        },        
        setSkuType:(state, action) =>{
            state.skuType = action.payload
        },
        setSku:(state, action) =>{
            state.sku =  _.uniqBy(action.payload, (obj) => obj.skuNbr);
        },
        setSelectedSku:(state, action) =>{
          state.seletedSku = action.payload
        },
        reset:(state,action)=>{
                state.catgoryData =[];
                state.selectedCategory=[];
                state.classData=[];
                state.selectedClass=[];
                state.subClassData=[];
                state.seletedSubClass=[];
                state.skuType='-1';
                state.sku=[];
                state.seletedSku=[];
                state.filterType = {name:"None",value:null}
        },
        setFilterType:(state, action) =>{         
          if(action.payload.value.length === 0) {
          if(!_.isEmpty(state.seletedSubClass)) {
              state.filterType = {name:"SubClass",value:state.seletedSubClass.map(ele=> ele.uniqCode)}
          }
          else if(!_.isEmpty(state.selectedClass)) {
            state.filterType = {name:"Class",value:state.selectedClass.map(ele=> ele.uniqCode)}
          }
          else if(!_.isEmpty(state.selectedCategory)) {
            state.filterType = {name:"Dept",value:state.selectedCategory.map(ele=> ele.uniqCode)}
          }else {
            state.filterType = {name:"None",value:null}
          }
           
          }
          else {
            state.filterType = action.payload;
          }
        },  
        setDropDownShow: (state, action) => {
          state.hideDropDown = action.payload
        }
    }
})
export const { finelineHierarchy, setSelectedCategory, setSelectedClass, setSubClassData, setSeletedSubClass,setSelectedSku, setSkuType, setSku, reset, setFilterType, setDropDownShow } = dropdownSlice.actions;
export const setDropDownData = (url) => (dispatch, getState) => {
    utilities.setLoader(true)
    dispatch(loadUserData(url)).then(res=>{
        dispatch(finelineHierarchy(res.payload.data))
        utilities.setLoader(false)
    }).catch(err=>{
        console.log(err)
        utilities.setLoader(false)
    })
  };
  export default dropdownSlice.reducer;
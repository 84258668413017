import React from 'react';
import { BarChart, Bar, XAxis, YAxis,  Tooltip,  ResponsiveContainer } from 'recharts';
import utilities from '../../utils/utilities';

export default function BarChartComp (props) {
 //const demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';
    return (
      <div style={{width:'100%', height:'100%'}}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={props.data}
          layout={'vertical'}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis type="number"  tickLine={false} tick={<CustomizedGroupTick data={props.data}/>}/>
          <YAxis type="category" dataKey="text" interval={0}/>
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="ytdMargin" fill={props.name === 'topTenChart'? '#2b9348': '#bf0603'} />
        </BarChart>
      </ResponsiveContainer>
      </div>
    );
}
const CustomizedGroupTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g>
      <g>
        <text x={x} y={y+5} fontSize={10} className="barChatXaxis">
        ${utilities.formatNumber(payload.value, true)}
        </text>
      </g>
    </g>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].payload.performerName}`}</p>
        <p className='marginAmount'>{`Margin: $${utilities.formatNumber(payload[0].payload.ytdMargin)}`}</p>
      </div>
    );
  }

  return null;
};
export const barChatText = (data) =>{
    if(data.length === 0) {
        return []
    }
    return data.map(item=>{
        let text = item.performerName
        if(text.split('').length > 8) {
          text = text.slice(0, 8)+'..'
        }
        return {
          ...item,
          text
        }
      })
}

export const COLORS = [
  '#1f77b4',  '#ff7f0e',  '#d62728',  '#9467bd',  '#8c564b', 
  '#e377c2',  '#7f7f7f',  '#bcbd22',  '#17becf',  '#aec7e8',
  '#ffbb78',  '#c5b0d5',  '#c49c94',  '#f7b6d2',  '#c7c7c7', 
  '#dbdb8d',  '#9edae5',	'#ff9896',	'#ff9d9a ',	'#f7b6d2',
  '#832161',	'#fc2f00',  '#386641',  '#6a994e',  '#e71d36', '#eeef20', '#c77dff',
  ];  

  export const DonutOneColors = [
    '#009900',  '#66cb02',  '#00cccd',  '#fc9900', 
    '#0065cd',  '#cd0099',  '#fe0000'
  ]; 
  export const DonutTwo = [
      '#cf0099',  '#0065cd','#fc9900',  
  ]; 
  export const StackedBarColor = [
    '#241468',  '#9F0D7F','#EA1179', '#F79BD3', '#9467bd' 
  ];
  export const storePriceColors = {
    '10GTFP': '#fe0000',
    '5TO10GTFP':'#cd0099',
    '0TO5GTFP' : '#0065cd',
    'EQFP': '#fc9900',
    '0TO5LTFP': '#00cccd',
    '5TO10LTFP':  '#66cb02',
    '10LTFP': '#009900',
  }
   export const priceViewColors = {
    'Decrease': '#cf0099',
    'Increase':'#0065cd',
  }
export const legend = {
  '10GTFP': 'RP 10% + ABOVE FP',
  '5TO10GTFP':'RP 5-10% ABOVE FP',
  '0TO5GTFP' : 'RP 0-5% ABOVE FP',
  'EQFP': 'RP @ FP',
  '0TO5LTFP': 'RP 0-5% BELOW FP',
  '5TO10LTFP':  'RP 5-10% BELOW FP',
  '10LTFP': 'RP 10% + BELOW FP',
}

export const StackedBarLegend = {
  'compRangePct_1':'Very Underpriced',
  'compRangePct_2':'Underpriced',
  'compRangePct_3':'Not Matched',
  'compRangePct_4':'Inline',
  'compRangePct_5':'Overpriced',
  'compRangePct_6':'Very Overpriced',
}

export const suggPriceLegend = {
  'avgWholesaleCost':'Wholesale Cost',
  'avgRegPrice':'Regular Price',
  'avgFillRateIndex':'Fill Rate Index',
  'avgAsp':'ASP',
  'totalUnits':'Units',
}

export const getMonth = {
    '01' :' Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04':'Apr',
    '05':'May',
    '06':'June',
    '07':'July',
    '08':'Aug',
    '09':'Sep',
    '10':'Oct',
    '11':'Nov',
    '12':'Dec'
}
import React, {memo, useEffect} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer,Tooltip } from 'recharts';
import _ from 'lodash';
import { priceViewColors, legend} from '../common/chartUtilities'
         
const renderColorfulLegendText = (value, entry) => {
  return (
    <span>
      {legend[entry.payload.priceRange]}
    </span>
  )
};
const PriceViewDonut = (props)=> { 
let data = props.data
let marginVal = 10
const RADIAN = Math.PI / 180;
if(!_.isEmpty(data)) {
  marginVal = data.map(ele => ele.priceChangePct).reduce((a,b)=> a+b)
  if(marginVal===0) {
    data = data.map(ele => {
      return {
        ...ele,
        priceChangePct: 2
      }
    })
  }
 
}

const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, priceChangeType, priceChangePct } = props

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN) ;
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
   <g>
     <defs>
     <filter id="rounded-corners" x="-20%" width="140%" y="-75%" height="250%">
          <feFlood floodColor="#222222ba"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComponentTransfer>
            <feFuncA type="table"tableValues="0 0 0 1"/>
          </feComponentTransfer>

          <feComponentTransfer>
            <feFuncA type="table"tableValues="0 1 1 1 1 1 1 1"/>
          </feComponentTransfer>
            <feComposite operator="over" in="SourceGraphic"/>
</filter>
</defs>
<text filter="url(#rounded-corners)"  x={x} y={y}  className='smallDonutText'  fill="red" textAnchor="center" >
  <tspan  x={x} dy="-1.2em">{priceChangeType}: {priceChangePct}%</tspan>
</text>
</g>
  );
};
 const handlePieSelect = (e) => {
  if(props.data.length> 1) {
   const path  = document.querySelectorAll('.piePath')
   if(e.target.classList.contains('activeRow')) {
    e.target.classList.remove('activeRow')
      path.forEach((item)=>{
         item.style.opacity = '1'
       })
       props.handleSelection([])
   }
   else {
      path.forEach((item)=>{
         item.classList.remove('activeRow')
         item.style.opacity = '0.5'
       })
       e.target.classList.add('activeRow')
       e.target.style.opacity = '1'
      props.handleSelection(e.target.getAttribute('id'))
   }       
  }
 }
 useEffect(()=>{
  if(!props.resetSelection || props.gridSelection) {
    const path  = document.querySelectorAll('.piePath')
    path.forEach((item)=>{
      item.classList.remove('activeRow')
      item.style.opacity = '1'
    })
  }

 },[props.resetSelection, props.gridSelection])

 useEffect(()=>{
  if(!_.isEmpty(props.isSinglePieCode)) {
    const id = document.getElementById(`${props.isSinglePieCode[0]}`)
    const path  = document.querySelectorAll('.piePath')
    path.forEach((item)=>{
      item.classList.remove('activeRow')
      item.style.opacity = '0.5'
    })
    id.classList.add('activeRow')
    id.style.opacity = '1'
  }
 })
    return (
        <div style={{width:'100%', height:'100%'}}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx= '50%'
            cy='50%'
            innerRadius={80}
             outerRadius={120}
            labelLine={false}
            isAnimationActive={false}
            animationDuration={400}
            animationEasing={'ease-out'}
            label={renderCustomizedLabel}
            fill="#000"
            dataKey="priceChangePct"
            blendStroke
          >
            {data.map((entry, index) => {
              return (
                  <Cell key={entry.priceChangeType} className="piePath" id={entry.priceChangeType} onClick={handlePieSelect} stroke= {!_.isEmpty(priceViewColors[entry.priceChangeType]) ?  priceViewColors[entry.priceChangeType] : '#fc9900'} fill={!_.isEmpty(priceViewColors[entry.priceChangeType]) ?  priceViewColors[entry.priceChangeType] : '#fc9900'} />
              )
            })}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
      </div>
    );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip dountTooltip">
        <p className='marginAmount'>{`Type:${payload[0].payload.priceChangeType}`}</p>
        <p className='marginAmount'>{`Percentage: ${payload[0].payload.priceChangePct}%`}</p>
      </div>
    );
  }

  return null;
};

export default memo(PriceViewDonut)
import React from 'react'
import {
    Modal
  } from 'semantic-ui-react';
import history from '../../history';
import { useImmer } from 'use-immer';
import ChangeStore from './ChangeStore';
import { useSelector } from 'react-redux'
import NeedHelp from './NeedHelp';
import { getRedirectUriMOL} from '../../urlConstants'
const HeroBar =(props)=> {
  const { userName, storeId, storeDetails, memberName } = useSelector(state => state.SessionReducer)
  const redirectHome = ()=>{
        history.push('/home');
    }
    const [ menuSetting , setMenuSetting] = useImmer({
        showNeedHelp:false,
        showChangeStore:false
    })
    const {showNeedHelp, showChangeStore } =  menuSetting
    const openChangesStoreModel = () =>{
        setMenuSetting(draft=>{
            draft.showChangeStore = true
        })
    }
    const openNeedHelpModel = () =>{
      setMenuSetting(draft=>{
        draft.showNeedHelp = true
    })
    }
    const closeChangeStore = () =>{
        setMenuSetting(draft=>{
            draft.showChangeStore = false
        })
    }
    const closeNeedHelp = () => {
      setMenuSetting(draft=>{
        draft.showNeedHelp = false
    })
    }
      const handlePopup = () => {
        setMenuSetting(draft=>{
          draft.showChangeStore = false
        });
      }
      const returnMol = () =>{
        window.location.assign(getRedirectUriMOL());
        localStorage.clear();
        sessionStorage.clear()
      }
  return (
    <div className='heroHeader ui stackable grid zero-margin' computer = {3} tablet = {2}>
        <div className="heroHeaderWrapper gridContainer">
            <div className = 'header-top-left'>
            <div className= 'cursor_p headerLogoImg' onClick={redirectHome}>
            </div>
            <h3>RPO-Price Optimization</h3>
            </div> 
            <div className='header_icons header-top-right' >
            <div className='userInfoWrapper'>
            <div className = 'header_user_icons_text'>
          <Modal closeIcon
            className='modelPopup'
            onClose = {closeChangeStore}
            closeOnDimmerClick={false}
            open={showChangeStore}>
            <Modal.Content className=''>
               <ChangeStore handlePopup ={handlePopup}storeId = {storeId} storeDetails = {storeDetails}/>
            </Modal.Content>
          </Modal>
          <Modal closeIcon
            className='modelPopup needHelpPopup'
            onClose = {closeNeedHelp}
            closeOnDimmerClick={false}
            open={showNeedHelp}>
              <Modal.Content className=''><NeedHelp /></Modal.Content>             
          </Modal>
          <div className='headerNameStore'>
            <p> {userName}</p>
            <p> Store# {storeId}</p>
            </div>
            <p>{memberName}</p>            
            </div> 
            <div className='header_user_icons'>
            <p onClick={openChangesStoreModel} className='name_card'>Switch Store <i className='caret down icon' /></p>
               <p onClick={openNeedHelpModel}>Need Help ?<i className='caret down icon' /></p>
               <p onClick={returnMol}>Return to MOL</p>
              </div>
              </div>
              </div> 
     </div>  
     </div>
  );
}

export default HeroBar;

import React, { memo, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { StackedBarLegend} from '../common/chartUtilities'
import _ from 'lodash';
const renderColorfulLegendText = (value, entry) => {
  return (
    <span>
      {StackedBarLegend[entry.dataKey]}
    </span>
  )
};
const StackedBarChart = (props) =>{
    const data = props.data
    const handlePieSelect = (e) => {
       const path  = document.querySelectorAll('.piePath')
       if(e.target.classList.contains('activeRow')) {
        e.target.classList.remove('activeRow')
          path.forEach((item)=>{
             item.style.opacity = '1'
           })
        props.handleSelection([])
       }
       else {
          path.forEach((item)=>{
             item.classList.remove('activeRow')
             item.style.opacity = '0.3'
           })
           e.target.classList.add('activeRow')
           e.target.style.opacity = '1'
          props.handleSelection(e.target.getAttribute('id'))
       }       
     }
    return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="compName" interval={0} />
            <YAxis domain={[0, 120]}tick={<CustomizedGroupTick data={data}/>}/>
            <Legend formatter={renderColorfulLegendText}/>
            <Tooltip content={<CustomTooltip />}/>
            <Bar dataKey='compRangePct_3' blendStroke barSize ={30} stackId="a" fill="#ffc000">
            {data.map((entry, index) => {
              return (
                <Cell id={`${entry.compId}_${3}`} key={entry.compName} className="piePath"  onClick={handlePieSelect} />
              )
            })}
            </Bar>
            <Bar dataKey='compRangePct_6' blendStroke barSize ={30}stackId="a" fill="#B71C1C">
            {data.map((entry, index) => {
              return (
                <Cell id={`${entry.compId}_${6}`} key={entry.compName} className="piePath"  onClick={handlePieSelect} />
              )
            })}
            </Bar>
            <Bar dataKey='compRangePct_5' blendStroke barSize ={30}stackId="a" fill="#f9656e">
            {data.map((entry, index) => {
             return (
              <Cell id={`${entry.compId}_${5}`} key={entry.compName} className="piePath"  onClick={handlePieSelect} />
            )
            })}
            </Bar>
            <Bar dataKey='compRangePct_4' blendStroke barSize ={30}stackId="a" fill="#0079f1">
            {data.map((entry, index) => {
              return (
                <Cell id={`${entry.compId}_${4}`} key={entry.compName} className="piePath"  onClick={handlePieSelect} />
              )
            })}
            </Bar>
            <Bar dataKey='compRangePct_2' blendStroke barSize ={30} stackId="a" fill="#3cc88b">
            {data.map((entry, index) => {
              return (
                <Cell id={`${entry.compId}_${2}`} key={entry.compName} className="piePath"  onClick={handlePieSelect} />
              )
            })}
            </Bar>
            <Bar dataKey='compRangePct_1' blendStroke barSize ={30} stackId="a" fill="#1c7533">
            {data.map((entry, index) => {
                return (
                  <Cell id={`${entry.compId}_${1}`} key={entry.compName} className="piePath"  onClick={handlePieSelect} />
                )
            })}
            </Bar>        
          </BarChart>
        </ResponsiveContainer>
      );
}
const CustomizedGroupTick = (props) => {
  const { index, x, y, payload } = props;
  return (
    <g>
      <g>
        <text x={index ===0 ? x-20 : x-25} y={y+4}>
        {payload.value}%
        </text>
      </g>
    </g>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip stackedBarCustomToolTip">
         <h4>Competitor Name: {payload[0].payload.compName}</h4>
         <div>
         {payload.map(ele=>{
          return (         
            <p  key = {ele.fill} style={{color:ele.fill}}>{StackedBarLegend[ele.dataKey]}: {ele.value}%</p>
          )
         })}
         </div>
      </div>
    );
  }

  return null;
};
export default memo(StackedBarChart);

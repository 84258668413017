import React from 'react'
import {mainMenu} from './menuConstants'
import { Link } from 'react-router-dom';
import history from '../../history';
const Flyout =(props)=> {
const currentPath = history.location.pathname
 const renderMenu = (menus) => {
    return menus.map((menu) => {
        const { key, title, url } = menu
        return (
            <Link className={currentPath === url ? 'activeMenu' : currentPath === '/finalizePriceOverride' && url==='/whatIfCalculator'? 'activeMenu':'' }key={key} to={url}>
            {title}
          </Link>
        )
    })
 }
  return (
      <div  className="mainMenuWrapper">
        <div className='gridContainer'>
          {renderMenu(mainMenu)}
        </div>
        </div>
  )
}
export default Flyout;

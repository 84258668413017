import React, { Component } from 'react';
import {  Router, Route, Switch } from 'react-router-dom';
import { logout } from '../../views/Login/actions';
import Home from '../../views/Home';
import Header from '../../components/NavBar/Header';
import AuthPage from './AuthPage';
import { connect } from 'react-redux';
import history from '../../history';
import { Loader } from 'semantic-ui-react';
import Footer from './Footer';
import About from '../About';
import PriceChange from '../PriceChange';
import SuggestedPrice from '../SuggestedPrice';
import CompetitorPrice from '../CompetitorPrice';
import WhatIfCalculator from '../whatIfCalculator';
import ProductMaster from '../ProductMaster';
import VariablePrice from '../VariablePrice';
import FinalizePriceOverride from '../whatIfCalculator/finalizePriceOverride'
import AddSkuProdcuctMaster from "../ProductMaster/addSkuProductMaster";
import AuthLoader from './AuthLoader';
import NotFound from '../../views/ErrorPages/404_error_page';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];
    this.clearSessionTimeout = this.clearSessionTimeout.bind(this);
    this.setSessionTimeout = this.setSessionTimeout.bind(this);

    for (let i in this.events) {
      window.addEventListener(this.events[i], this.clearSessionTimeout);
    }
    this.setSessionTimeout();
  }
  clearSessionTimeout() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    this.setSessionTimeout();
  }

  setSessionTimeout() {
    this.logoutTimeout = setTimeout(() => {
      this.props.history.push('/');
      this.props.logout();
    }, 30 * 60 * 1000);
  }

  render() {
    return (
      <>
        <Header content={this.props.children} />
        <Footer/>
      </>
    );
  }
}

const mapStateToLayoutProps = state => {
  return {
    ...state.SessionReducer
  };
};

const LayoutContainer = connect(mapStateToLayoutProps, { logout })(Layout);

class RenderRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader:false,
      renderComp: false,
      // If we need Layout and AuthErrorBoundary for a screen, need_layout_and_auth flag should be yes
      // If we have to pass any params to a screen need_props should be yes.
      list_of_pages: [
        // {
        //   path: '/error',
        //   component: ErrorPage,
        //   need_props: 'no'
        // },
        {
          path: '/',
          component: Home,
          need_props: 'no'
        },
        {
          path: '/index.html',
          component: Home,
          need_props: 'no'
        },
        {
          path: '/home',
          component: Home,
          need_props: 'no'
        },
        {
          path: '/priceChangeSummary',
          component: PriceChange,
          need_props: 'no'
        },
        {
          path: '/suggestedPricingDetail',
          component: SuggestedPrice,
          need_props: 'no'
        },
        {
          path: '/competitorPrices',
          component: CompetitorPrice,
          need_props: 'no'
        },
        {
          path: '/whatIfCalculator',
          component: WhatIfCalculator,
          need_props: 'yes'
        },
        {
          path: '/productMasterList',
          component: ProductMaster,
          need_props: 'yes'
        },
        {
          path: '/variablePriceChange',
          component: VariablePrice,
          need_props: 'no'
        }, 
        {
          path: '/finalizePriceOverride',
          component: FinalizePriceOverride,
          need_props: 'yes'
        },
        {
          path: '/addSkuToProductMaster',
          component: AddSkuProdcuctMaster,
          need_props: 'yes'
        },

        {
          path: '',
          component: NotFound,
          need_props: 'no'
        }     

       
      ]
    };
  }
  componentWillReceiveProps(newProps){
    if(newProps.showLoader!==this.state.showProps){
      this.setState({showLoader:newProps.showLoader});
    }
    if(newProps.storeId !== this.state.renderComp) {
      this.setState({renderComp: newProps.storeId})
    }
  }
  getRenderRoute(component, props) {
    const {showLoader} = this.state;
    return (
      <AuthPage>
        <LayoutContainer
          isAuthenticated={this.props.isAuthenticated}
          {...props}
        >
          {this.props.storeId && component}
          {showLoader && <div className='commonLoader'><Loader active/></div>}
        </LayoutContainer>
        </AuthPage>
    );
  }

  render() {
    return (
      <>
       {!this.props.isAuthenticated && <AuthLoader /> }
      <Router history={history}>
        <Switch>
            {this.state.list_of_pages.map((item, index) =>
              (
                <Route
                  key={index}
                  exact
                  path={item.path}
                  render={props =>
                    this.getRenderRoute(<item.component {...props} />, props)
                  }
                />
              )
            )}
            </Switch>
   

      </Router>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.SessionReducer.isAuthenticated,
  showLoader : state.UtilityReducer.showLoader,
  storeId: state.SessionReducer.storeId
});

export default connect(mapStateToProps)(RenderRoutes);

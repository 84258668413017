import React, { useState, memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";
import DropDown from "./Dropdown";
import {
  productMasterFinelineHierarchy,
  productMasterUPCDetails,
  addSkuProductMasterList,
} from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import utilities from "../../utils/utilities";
import {TV_SKU_CONFLICT,ITEM_EXISTS,INVALID_TV_SKU,UNABLE_TO_ADD,SUCCESS,UPC_REQUIRED,UPC_LENGTH } from "./constant"
const AddSkuComponent = (props) => {
  const { storeId } = useSelector((state) => state.SessionReducer);
  const [inputs, setInputs] = useState({});
  const [finelineHierarchy, setFinelineHierarchy] = useState([]);
  const [isSku, setIsSku] = useState(false);
  const [isUPC, setIsUPC] = useState(false);
  const [disconInd, setDisconInd] = useState(false);
  const [isTvSku, setIsTvSku] = useState(false);
  const [ischeckActive, setIscheckActive] = useState(false);
  const [isSkuEditable, setIsSkuEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [dropDownData, setDropDownData] = useImmer({
    finelineHierarchy: [],
    category: [],
    class: [],
    subClass: [],
  });
  const [selectedItem, setSelectedItem] = useImmer({
    category: [],
    class: [],
    subClass: [],
  });
  const [filterType, setFilterType] = useImmer({
    name: "None",
    value: [],
  });

  const handleSelectedCategory = (items) => {
    const values = items.map((item) => item.value);
    const data = dropDownData.finelineHierarchy.filter((ele) => {
      if (values.includes(ele.deptCode)) {
        return ele;
      }
      return null;
    });
    setInputs({ ...inputs, dept: items[0].value });
    setFormErrors({});
    setSelectedItem((draft) => {
      draft.category = items;
      draft.class = [];
      draft.subClass = [];
    });
    setDropDownData((draft) => {
      draft.class = _.uniqBy(data, (obj) => obj.className);
    });
  };
  const handleSelectedClass = (item) => {
    const values = item.map((item) => item.uniqCode);
    const data = dropDownData.finelineHierarchy.filter((ele) => {
      if (values.includes(ele.uniqClassId)) {
        return ele;
      }
      return null;
    });
    setInputs({ ...inputs, class: item[0].value });
    setFormErrors({});
    setSelectedItem((draft) => {
      draft.class = item;
      draft.subClass = [];
    });
    setDropDownData((draft) => {
      draft.subClass = _.uniqBy(data, (obj) => obj.subClassName);
    });
  };
  const handleSelectedSubClass = (item) => {
    setSelectedItem((draft) => {
      draft.subClass = item;
    });
    setInputs({ ...inputs, subclass: item[0].value });
  };

  const handleFilterType = (item) => {
    if (item.value.length === 0) {
      if (!_.isEmpty(selectedItem.subClass)) {
        setFilterType((draft) => {
          draft.name = "SubClass";
          draft.value = selectedItem.subClass.map((ele) => ele.uniqCode);
        });
      } else if (!_.isEmpty(selectedItem.class)) {
        setFilterType((draft) => {
          draft.name = "Class";
          draft.value = selectedItem.class.map((ele) => ele.uniqCode);
        });
      } else if (!_.isEmpty(selectedItem.category)) {
        setFilterType((draft) => {
          draft.name = "Dept";
          draft.value = selectedItem.category.map((ele) => ele.uniqCode);
        });
      } else {
        setFilterType((draft) => {
          draft.name = "None";
          draft.value = null;
        });
      }
    } else {
      setFilterType((draft) => {
        draft.name = item.name;
        draft.value = item.value;
      });
    }
  };

  const handleChange = (event) => {
    setFormErrors({});
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleCheckbox = (event) => {
    const { value } = event.target;
    setIsSku(!isSku);
    if (isTvSku) {
      setIsTvSku(!isTvSku);
    }
  };
  const handleCheckbox2 = (event) => {
    setIsTvSku(!isTvSku);
    if (isSku) {
      setIsSku(!isSku);
    }
  };

  useEffect(() => {
    restUtils
      .getData(`${productMasterFinelineHierarchy}`)
      .then((res) => {
        const data = res.data.finelineHierarchy;
        setFinelineHierarchy(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [storeId, filterType]);

  const validate = (values) => {
    const errors = {};
    const _regexSKU = /^[0-9a-zA-Z\_]+$/;
    if (!values.upc) {
      errors.upc = "UPC# is required!";
    } else if (values?.upc.length > 14) {
      errors.upc = "UPC# shouldn't be more than 14 digits.";
    }
    if (!values.sku || values?.sku.length ===0) {
      errors.sku = "SKU# is required!";
    } else if (values.sku >= 100000 && values.sku <= 999999 && !isUPC) {
      errors.sku = TV_SKU_CONFLICT;
    } else if (values?.sku?.length > 100 ) {
      errors.sku = "SKU# shouldn't be more than 100 character.";
    } else if (!_regexSKU.test(values?.sku)) {
      errors.sku = "Special characters are not allowed.";
    }
    if (!values.description) {
      errors.description = "Description is required!";
    } else if (values?.description?.length > 100 && !isUPC) {
      errors.description = "Description should be less than 100 character.";
    }
    if (!values?.dept || values?.dept.length === 0) {
      errors.category = "Category is required!";
    }
    if (!values.class || values?.class.length === 0) {
      errors.class = "Class is required!";
    }
    if (!values.subclass || values?.subclass.length === 0) {
      errors.subClass = "SubClass is required!";
    }
    return errors;
  };

  const zeroPad = (num) => {
    return num.toString().padStart(14, "0");
  };

  useEffect(() => {
    if (!isSku && isTvSku) {
      setIsSkuEditable(false);
      setDropDownData((draft) => {
        draft.finelineHierarchy = finelineHierarchy;
        draft.category = _.uniqBy(finelineHierarchy, (obj) => obj.deptName);
      });
      setDisconInd(false);
      setInputs({
        ...inputs,
        sku: "",
        // description: "",
      });
      setSelectedItem((draft) => {
        draft.category = [];
        draft.class = [];
        draft.subClass = [];
      });
      setFormErrors({});
    } else if (isSku && !isTvSku) {
      getVerifiedUPC();
    }
  }, [isSku, isTvSku]);

  const getVerifiedUPC = () => {
    let _upc = zeroPad(inputs.upc);
    // _upc = "00026508281376";
    setIsUPC(false);
    setInputs({
      ...inputs,
      sku: "",
      description: "",
      category: [],
      class: [],
      subclass: [],
      className: "",
      deptName: "",
      subClassName: "",
    });
    const errors = {};
    if (isNaN(inputs?.upc)) {
      errors.upc = UPC_REQUIRED;
      setFormErrors(errors);
      setIsUPC(false);
      setIsSku(false);
      setIsTvSku(false);
      return;
    } else if (inputs?.upc.length > 14) {
      errors.upc = UPC_LENGTH;
      setFormErrors(errors);
      setIsUPC(false);
      setIsSku(false);
      setIsTvSku(false);
      return;
    }
    setFormErrors(errors);
    const payload = {
      itemNbr: null,
      upc: _upc,
    };
    setIsLoading(true);
    restUtils
      .postData(productMasterUPCDetails, payload)
      .then((response) => {
        const data = response.data;
        if (Object.keys(data).length > 0) {
          const { dept, subclass, upcs, disconInd } = data;
          const newclass = data.class;
          const _sku = upcs?.filter((el) => el.primaryInd === true) || [];
          if (upcs.length > 0) {
            let _op = finelineHierarchy.filter((ele) => {
              if (
                dept.includes(ele.deptCode) &&
                String(newclass).includes(ele.classCode) &&
                String(subclass).includes(ele.subClassCode)
              ) {
                return ele;
              }
              return null;
            });
            if (disconInd) {
              setDisconInd(true);
            }
            setIsLoading(false);
            setIscheckActive(false);
            setIsUPC(true);
            setIsSkuEditable(true);
            setIsSku(true);
            setIsTvSku(false);
            setInputs({
              ...inputs,
              description: data.shortDesc,
              sku: data.itemNbr,
              dept: _op[0].deptCode,
              className: _op[0].className,
              deptName: _op[0].deptName,
              class: _op[0].classCode,
              subClassName: _op[0].subClassName,
              subclass: _op[0].subClassCode,
            });
          } else {
            setIsLoading(false);
            setIsUPC(false);
            setIsSkuEditable(false);
            setIsSku(false);
            setIsTvSku(true);
            setIscheckActive(false);
            setDropDownData((draft) => {
              draft.finelineHierarchy = finelineHierarchy;
              draft.category = _.uniqBy(
                finelineHierarchy,
                (obj) => obj.deptName
              );
            });
          }
        } else {
          setIsLoading(false);
          setIsSku(false);
          setIsTvSku(true);
          setIscheckActive(true);
          setDropDownData((draft) => {
            draft.finelineHierarchy = finelineHierarchy;
            draft.category = _.uniqBy(finelineHierarchy, (obj) => obj.deptName);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error:", err);
      });
  };
  const resetForm = () => {
    setInputs({});
    setIsUPC(false);
    setIsSkuEditable(false);
    setIsSku(false);
    setIsTvSku(false);
    setIscheckActive(false);
    setSelectedItem((draft) => {
      draft.category = [];
      draft.class = [];
      draft.subClass = [];
    });

    setDropDownData((draft) => {
      draft.class = [];
      draft.subClass = [];
      draft.sku = [];
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const _emptyFields = validate(inputs);
    setFormErrors(_emptyFields);
    const _errArray = Object.keys(_emptyFields);
    if (_errArray?.length > 0) {
      return;
    }
    const payload = {
      memberNbr: storeId,
      upc: zeroPad(inputs.upc),
      skuNbr: String(inputs.sku),
      isTvSku: isSku,
      description: inputs.description,
      dept: inputs.dept,
      class: inputs.class,
      subClass: inputs.subclass,
      deptName: !_.isEmpty(selectedItem.category[0]?.name) ? selectedItem.category[0].name : inputs.deptName,
      className: !_.isEmpty(selectedItem.class[0]?.name) ? selectedItem.class[0].name : inputs.className,
      subClassName:!_.isEmpty(selectedItem.subClass[0]?.name) ? selectedItem.subClass[0].name : inputs.subClassName,    
    };
    setIsLoading(true);
    restUtils
      .postData(addSkuProductMasterList, payload)
      .then((response) => {
        resetForm();
        setIsLoading(false);
        utilities.showToast(SUCCESS);
      })
      .catch((err) => {
        setIsLoading(false);
        const code = err?.response?.data?.code;
        const msg = err?.response?.data?.message;
       
        if (code === "ITEM_EXISTS") {
          utilities.showToast(ITEM_EXISTS);
        }else if (code === "TV_SKU_CONFLICT") {         
          utilities.showToast(TV_SKU_CONFLICT);
        }else if (code === "INVALID_TV_SKU") {         
          utilities.showToast(INVALID_TV_SKU);
        } else{
          utilities.showToast(UNABLE_TO_ADD);
        }     
        
      });
  };
  return (
    <div>
      <section className="priceOverrideWrapper">
        <div className="backLinkDiv">
          <Link to="/productMasterList">Back to Product Master </Link>
        </div>
      </section>
      <section>
        <form className="formContainer" onSubmit={handleSubmit}>
          <div>
            <div className="inputContainer">
              <h4 className="lableText">UPC #</h4>
              <div>
                <input
                  className={
                    formErrors?.upc ? "inputBox errorInput" : "inputBox"
                  }
                  type="text"
                  name="upc"
                  value={inputs.upc || ""}
                  onChange={handleChange}
                  onBlur={getVerifiedUPC}
                />
                <p className="warn-text p1-lt">{formErrors?.upc}</p>
              </div>
            </div>

            <div className="inputContainer">
              <h4 className="lableText">TV SKU</h4>
              <div className="checkBoxContainer m1-lt">
                <div className="checkBoxLable ">
                  <input
                    disabled={ischeckActive}
                    className="m5-rt"
                    type="checkbox"
                    name="tvSKU"
                    checked={isSku || ""}
                    onChange={handleCheckbox}
                  />
                  <span>Yes</span>
                </div>
                <div className="checkBoxLable m1-lt">
                  <input
                    disabled={ischeckActive}
                    className="m5-rt"
                    type="checkbox"
                    name="tvSKU"
                    checked={isTvSku || ""}
                    onChange={handleCheckbox2}
                  />
                  <span>No</span>
                </div>
              </div>
            </div>
            {disconInd && (
              <div className="inputContainer">
                <h4 className="lableText">Discontinued SKU</h4>
                <div className="checkBoxContainer m1-lt">
                  <div className="checkBoxLable ">
                    <input
                      disabled
                      className="m5-rt"
                      type="checkbox"
                      name="disconInd"
                      checked={disconInd || ""}
                      // onChange={handleCheckbox}
                    />
                    <span>Yes</span>
                  </div>
                </div>
              </div>
            )}
            <div className="inputContainer">
              <h4 className="lableText">SKU#</h4>
              <div>
                <input
                  disabled={isSkuEditable}
                  className={
                    formErrors?.sku
                      ? "inputBox m1-lt errorInput"
                      : "inputBox m1-lt"
                  }
                  type="text"
                  name="sku"
                  value={inputs.sku || ""}
                  onChange={handleChange}
                />
                <p className="warn-text p1-lt">{formErrors?.sku}</p>
              </div>
            </div>

            <div className="inputContainer">
              <h4 className="lableText">Description</h4>
              <div>
                <input
                  disabled={isUPC}
                  className={
                    formErrors?.description ? "inputBox errorInput" : "inputBox"
                  }
                  type="text"
                  name="description"
                  value={inputs.description || ""}
                  onChange={handleChange}
                />
                <p className="warn-text p1-lt">{formErrors?.description}</p>
              </div>
            </div>

            <div className="inputContainer">
              <h4 className="lableText">Category</h4>
              <div>
                {isUPC ? (
                  <input
                    disabled
                    className="inputBox m1-lt"
                    type="text"
                    value={inputs.deptName || ""}
                  />
                ) : (
                  <div className="m1-lt">
                    <DropDown
                      dropDownStyle={formErrors?.category && "errorInput"}
                      id="Dept"
                      data={dropDownData.category}
                      selectedItem={selectedItem.category}
                      handleSelectedItem={handleSelectedCategory}
                      handleFilterType={handleFilterType}
                    />
                  </div>
                )}
                <p className="warn-text p1-lt">{formErrors?.category}</p>
              </div>
            </div>

            <div className="inputContainer">
              <h4 className="lableText">Class</h4>
              <div>
                {isUPC ? (
                  <input
                    disabled
                    className="inputBox"
                    type="text"
                    value={inputs.className || ""}
                  />
                ) : (
                  <div className="m1-lt">
                    <DropDown
                      dropDownStyle={formErrors?.class && "errorInput"}
                      id="Class"
                      data={dropDownData.class}
                      selectedItem={selectedItem.class}
                      handleSelectedItem={handleSelectedClass}
                      handleFilterType={handleFilterType}
                    />
                  </div>
                )}

                <p className="warn-text p1-lt">{formErrors?.class}</p>
              </div>
            </div>

            <div className="inputContainer">
              <h4 className="lableText">SubClass</h4>
              <div>
                {isUPC ? (
                  <input
                    disabled
                    className="inputBox"
                    type="text"
                    value={inputs.subClassName || ""}
                  />
                ) : (
                  <div className="m1-lt">
                    <DropDown
                      dropDownStyle={formErrors?.subClass && "errorInput"}
                      id="SubClass"
                      data={dropDownData.subClass}
                      selectedItem={selectedItem.subClass}
                      handleSelectedItem={handleSelectedSubClass}
                      handleFilterType={handleFilterType}
                    />
                  </div>
                )}
                <p className="warn-text p1-lt">{formErrors?.subClass}</p>
              </div>
            </div>
          </div>
          <div className="btnSubmit">
            <button className="addToSKU d_grid" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </form>
      </section>
      {isLoading && <Loader active />}
    </div>
  );
};

export default memo(AddSkuComponent);

export const header = {
  'TabOne':[
    {
       name:'SKU #',
       key:'skuNbr'
    },
    {
       name:'Description',
       key:'shortDesc'
    },
    {
       name:'Avg Cost ($)',
       key:'avgCost'
    },
    {
       name:'Current SRP ($)',
       key:'currentSrp'
    },
    {
       name:'Regular Price ($)',
       key:'regularPrice'
    },
    {
       name:'Price Sensitivity',
       key:'priceSensitivity'
    },
    {
       name:'YTD Units',
       key:"ytdUnits",
    },
    {
       name:'LY YTD Units',
       key:'ytdUnitsLY'
    },
    {
       name:'YTD Units Change',
       key:'ytdUnitsChangePct'
    },
    {
       name:'YTD Margin ($)',
       key:'ytdMargin'
    },
    {
       name:'LY YTD Margin ($)',
       key:'ytdMarginLY'
    },
    {
       name:'YTD Margin Change',
       key:'ytdMarginChangePct'
    },
    {
       name:'LTM Units',
       key:'ltmUnits1'
    },
 
    {
       name:'LY LTM Units',
       key:'ltmUnits2'
    },
    {
       name:'Units Change',
       key:'ltmUnitsChangePct'
    },
    {
       name:'LTM Margin ($)',
       key:'ltmMargin1'
    },
    {
       name:'LY LTM Margin ($)',
       key:'ltmMargin2'
    },
    {
       name:'Margin Change',
       key:'ltmMarginChangePct'
    }],
   'TabFive':[
   {
      name:'SKU #',
      key:'skuNbr'
   },
   {
      name:'Description',
      key:'shortDesc'
   },
   {
      name:'Avg Cost($)',
      key:'avgCost'
   },
   {
      name:'Replacement Cost ($)',
      key:'replCost'
   },
   {
      name:'Current SRP ($)',
      key:'currentSrp'
   },
   {
      name:'Yearly Avg Units',
      key:'yearlyAvgUnits'
   },
   {
      name:'Prior Price',
      key:"priorPrice",
   },
   {
      name:'Regular Price ($)',
      key:'regularPrice'
   },
   {
      name:'Home Depot ($)',
      key:'compPrice1'
   },
   {
      name:'Lowes ($)',
      key:'compPrice2'
   },
   {
      name:'Menards ($)',
      key:'compPrice3'
   },
   {
      name:'Ace ($)',
      key:'compPrice4'
   },
   {
      name:'TV Avg ($)',
      key:'compPrice5'
   },

   {
      name:'Sensitivity',
      key:'priceSensitivity'
   },
   {
      name:'FP Range',
      key:'fpRange'
   },
   {
      name:'FP ($)',
      key:'fairPrice'
   },
   {
      name:'Exp An. Margin Improv ($)',
      key:'marginChange'
   }],
   'priceOverride':[
      {
         name:'SKU #',
         key:'skuNbr'
      },
      {
         name:'Name',
         key:'shortDesc'
      },
      {
         name:'Category',
         key:'deptName'
      },
      {
         name:'Old Price',
         key:'oldPrice'
      },
      {
         name:'New Price',
         key:'newPrice'
      }],
      'storePricingMix':[
         {
            name:'SKU #',
            key:'skuNbr'
         },
         {
            name:'Description',
            key:'shortDesc'
         },
         {
            name:'Department',
            key:'deptName'
         },
         {
            name:'Class',
            key:'className'
         },
         {
            name:'Current SRP ($)',
            key:'currentSrp'
         },
         {
            name:'Regular Price ($)',
            key:'regularPrice'
         },
         {
            name:'Fair Price ($)',
            key:'fairPrice'
         },
         {
            name:'Exp. An. Margin Improv. ($)',
            key:'marginChange'
         },
         {
            name:'Price Override ($)',
            key:'priceOverride'
         }],
         'priceView':[
            {
               name:'SKU #',
               key:'skuNbr'
            },
            {
               name:'Description',
               key:'shortDesc'
            },
            {
               name:'Department',
               key:'deptName'
            },
            {
               name:'Class',
               key:'className'
            },
            {
               name:'Current SRP ($)',
               key:'currentSrp'
            },
            {
               name:'Regular Price ($)',
               key:'regularPrice'
            },
            {
               name:'Fair Price ($)',
               key:'fairPrice'
            },
            {
               name:'Exp. An. Margin Improv. ($)',
               key:'marginChange'
            },
            {
               name:'Price Override ($)',
               key:'priceOverride'
            }],
  productMasterList: [
    {
      name: "SKU #",
      key: "skuNbr",
    },
    {
      name: "TV SKU (Yes/No)",
      key: "isTvSku",
    },
    {
      name: "Category",
      key: "deptName",
    },
    {
      name: "Class",
      key: "className",
    },
    {
      name: "SubClass",
      key: "subclassName",
    },
    {
      name: "Description",
      key: "shortDesc",
    },
    {
      name: "UPC #",
      key: "upc",
    },
    {
      name: "Remove",
      key: "checkbox",
    },
  ],
  'competitorPrices':[
   {
      name:'SKU #',
      key:'skuNbr'
   },
   {
      name:'Description',
      key:'shortDesc'
   },
   {
      name:'Avg Cost ($)',
      key:'avgCost'
   },
   {
      name:'Replace Cost ($)',
      key:'replCost'
   },
   {
      name:'Current SRP ($)',
      key:'currentSrp'
   },
   {
      name:'Yearly Avg Units',
      key:'yearlyAvgUnits'
   },
   {
      name:'Prior Price',
      key:'priorPrice'
   },
   {
      name:'Regular Price ($)',
      key:'regularPrice'
   },
   {
      name:'Home Depot ($)',
      key:'compPrice1'
   },
   {
      name:'Lowes ($)',
      key:'compPrice2'
   },
   {
      name:'Menards ($)',
      key:'compPrice3'
   },
   {
      name:'ACE ($)',
      key:'compPrice4'
   },
   {
      name:'TV Avg ($)',
      key:'tvAvgPrice'
   },
   {
      name:'Sensitivity',
      key:'priceSensitivity'
   },
   {
      name:'FP Range',
      key:'fpRange'
   },
   {
      name:'Fair Price ($)',
      key:'fairPrice'
   },
   {
      name:'Exp. An. Margin Improv.($)',
      key:'marginChange'
   }],
   'priceDetailPopUp': [
      {
        name: "Name",
        key: "compName",
      },
      {
        name: "Comp SKU",
        key: "compSku",
      },
      {
        name: "Description",
        key: "compSkuDesc",
      },
      {
        name: "Price",
        key: "compPrice",
      },
      {
        name: "Type",
        key: "matchType",
      },
    ],
    'suggPrice':[
      {
         name:'SKU #',
         key:'skuNbr'
      },
      {
         name:'Description',
         key:'shortDesc'
      },
      {
         name:'Avg Cost ($)',
         key:'avgCost'
      },
      {
         name:'Replace Cost ($)',
         key:'replCost'
      },
      {
         name:'Current SRP ($)',
         key:'currentSrp'
      },
      {
         name:'Yearly Avg Units',
         key:'yearlyAvgUnits'
      },
      {
         name:'Prior Price',
         key:'priorPrice'
      },
      {
         name:'Regular Price ($)',
         key:'regularPrice'
      },
      {
         name:'Home Depot ($)',
         key:'compPrice1'
      },
      {
         name:'Lowes ($)',
         key:'compPrice2'
      },
      {
         name:'Menards ($)',
         key:'compPrice3'
      },
      {
         name:'ACE ($)',
         key:'compPrice4'
      },
      {
         name:'TV Avg ($)',
         key:'tvAvgPrice'
      },
      {
         name:'Sensitivity',
         key:'priceSensitivity'
      },
      {
         name:'FP Range',
         key:'fpRange'
      },
      {
         name:'Fair Price ($)',
         key:'fairPrice'
      },
      {
         name:'Exp. An. Margin Improv.($)',
         key:'marginChange'
      }],
};
